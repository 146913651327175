import React,{useState, useEffect, useContext,useRef} from 'react';
import FullCalendar from '@fullcalendar/react';
import { formatDate } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';

import Cookies from 'js-cookie';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import thLocal  from '@fullcalendar/core/locales/th';

import { AuthContext } from '../helpers/AuthContex';

import {Modal , DatePicker , Button, Input, Select, Form, ColorPicker,theme } from 'antd'

import dayjs from 'dayjs';
import 'dayjs/locale/th'; 
import axios from 'axios';


import Secheduler from '../asset/Logo/scheduler.webp'
import { generate, green, presetPalettes, red } from '@ant-design/colors';

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map(([label, colors]) => ({
    label,
    colors,
  }));



const {RangePicker} = DatePicker
const { TextArea } = Input;


axios.defaults.headers.common['accessToken'] = Cookies.get('accessToken');
axios.defaults.headers.post["Content-Type"] = "application/json";

const MainSchedule = () => {
  const { token } = theme.useToken();
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
  });

  const{authState} = useContext(AuthContext)

  const [isModalOpen, setIsModalVisible] = useState(false)
  const [isModalOpen1, setIsModalVisible1] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const [isFormValid, setIsFormValid] = useState(false);
  

  const [events, setEvents] = useState([])

  
  const [Eventdetail , setEventdetail] = useState(null);


  // Data
  const [appointment, setappointment] = useState({
    title:"",
    start:"",
    end:"",
    descripton:"",
    patient_id: "",
    employee_id:"",
    department_id: authState.department_id
  })

  const [patientValue, setPatientValue] = useState(null); 
  const [descriptionValue, setDescriptionValue] = useState(""); 
  const [employeeValue, setEmployeeValue] = useState(null);

  const [lastUpdatedDate, setLastUpdatedDate] = useState(null)
  const [tempLastUpdatedDate, setTempLastUpdatedDate] = useState(null)


  const [employee, setEmployee] = useState([])
  const [patients, setPatient] = useState([])

  const [options, setoptions] = useState([])

  const [patientoptions, setpatientoptions] = useState([])


  const [isLoading, setLoading] = useState(true);

  const [test, setTest] = useState({});

  const [resource, setResource] = useState([])

  const [isEdit, setIsEdit] = useState(false)


  const calendarRef = useRef(null);

  const [form] = Form.useForm();

  const [eventColor,setEventColor] = useState("#7171FF")



  const [isNewPatient, setIsNewPatient] = useState(false)



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [appointmentRes, lastUpdateRes, employeesRes, patientsRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/appointment/byId/${authState.department_id}`, { headers:{accessToken: Cookies.get('accessToken'),},}),
          axios.get(`${process.env.REACT_APP_API_URL}/appointment/lastupdate-date/byId/${authState.department_id}`, { headers:{accessToken: Cookies.get('accessToken'),},}),
          axios.get(`${process.env.REACT_APP_API_URL}/employee/byId/${authState.department_id}`, { headers:{accessToken: Cookies.get('accessToken'),},}),
          axios.get(`${process.env.REACT_APP_API_URL}/patient/bydp/${authState.department_id}`, { headers:{accessToken: Cookies.get('accessToken'),},})
        ]);
        dayjs.locale('th');
        const formattedDate = dayjs(lastUpdateRes.data?.lastUpdateDate).format('DD/MM/YYYY HH:mm:ss');
        setLastUpdatedDate(formattedDate);
        setTempLastUpdatedDate(formattedDate)
        setEvents(appointmentRes.data);
        const additionalEmployees = [
          { id: 999999, name: "ไม่มี(นวดอย่างเดียว)" },
          { id: 9999999, name: "ไม่มี(ลูกค้าใหม่)" },
          { id: 99999999, name: "ไม่มี(หมายเหตุ)" }
        ];
        setEmployee([...employeesRes.data, ...additionalEmployees]);
  
        const employeeOptions = [
          ...employeesRes.data.map(item => ({ value: item.id.toString(), label: item.name })),
          ...additionalEmployees.map(item => ({ value: item.id.toString(), label: item.name }))
        ];
        setoptions(employeeOptions);
        const physiotherapists = employeesRes.data.filter(item => item.role === "นักกายภาพบำบัด");
        const resourceItems = [
          ...physiotherapists.map(item => ({ id: item.id.toString(), title: `(${item.nickname})` })),
          { id: "999999", title: "นวดอย่างเดียว" },
          { id: "9999999", title: "ลูกค้าใหม่" },
          { id: "99999999", title: "หมายเหตุ" }
        ];
        setResource(resourceItems);
  
        setPatient(patientsRes.data);
        setpatientoptions([
          ...patientsRes.data.map(item => ({ value: item.id.toString(), label: item.name })),
          { value: "999999", label: "[กรอกเอง]" }
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    validateForm();
  }, [patientValue, employeeValue,isNewPatient]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        if (calendarRef.current) {
          calendarRef.current.getApi().setOption('aspectRatio', 1);
        }
      } else {
        if (calendarRef.current) {
          calendarRef.current.getApi().setOption('aspectRatio', 2);
        }
      }
    };
  
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };


  }, []);

  const getLastUpdatedAt = async () =>{
    const lastupdatedAt = await axios.get(`${process.env.REACT_APP_API_URL}/appointment/lastupdate-date/byId/${authState.department_id}`, { headers:{accessToken: Cookies.get('accessToken'),},})
    console.log(lastupdatedAt)
    const lastUpdated = dayjs(lastupdatedAt.data?.updatedAt).format('DD/MM/YYYY HH:mm:ss')
    setLastUpdatedDate(lastUpdated)
    setTempLastUpdatedDate(lastUpdated)
  }

  


  const validateForm = () => {
    if (patientValue   &&  appointment.start && appointment.end) {
      
      if(patientValue === "[กรอกเอง]")
      {
        if(isNewPatient){
          console.log(isNewPatient)
          setIsFormValid(true);
        }else{
          setIsFormValid(false);
        }
      }else{
        setIsFormValid(true)
      }
    } else {
      setIsFormValid(false);
    }
  };

  const handleSelect = (info) => {
    
    const viewType = info.view.type;
    const calendar = calendarRef.current.getApi();

    

    if (viewType === 'dayGridMonth')
    {
      calendar.changeView('resourceTimeGridDay',info.startStr);
    }
    else if (viewType === 'resourceTimeGridDay')
    {
      if(authState.department_id === 0){return};
      showModal();
      setEmployeeValue(info.resource.id)
      setappointment({
        ...appointment,
        start: info.startStr,
        end: info.endStr,
        resourceId:info.resource.id.toString()
      });
      console.log(appointment)
      setPatientValue();
    }
    
  };

  const handleViewChange = (view) => {
    const viewType = view?.view?.type;

    if (viewType === "dayGridMonth") {
      setLastUpdatedDate(tempLastUpdatedDate);
      return;
    }

    const calendarApi = calendarRef?.current?.getApi();
    const currentDate = dayjs(calendarApi?.getDate()).format('DD/MM/YYYY');

    const todayEvents = events.filter(item => 
      dayjs(item.start).format('DD/MM/YYYY') === currentDate
    );
    const lastEvent = todayEvents.reduce((latest, event) => {
      return new Date(event.updatedAt) > new Date(latest.updatedAt) ? event : latest;
    }, todayEvents[0]);

    const lastUpdatedDate = lastEvent 
      ? dayjs(lastEvent.updatedAt).format('DD/MM/YYYY HH:mm:ss') 
      : "ไม่มี";
    setLastUpdatedDate(lastUpdatedDate);
  }


  const showModal = () =>
  {
    setIsModalVisible(true);
  }

  const showModal1 = () =>
  {
    setIsModalVisible1(true);
  }

  const handleClick = (info) => {
    
    const data = events.find((event) => parseInt(event.id) === parseInt(info.event.id))

    const employeeData = employee.find((emp) => emp.id === data.employee_id)
    const transformData = 
    {
      ...data ,employee_name: employee ? employeeData?.name : "Unknown"  ,color:data.color,
    }

    console.log(data.color)
    setEventdetail(transformData);

    showModal1();
  };


  const ClearData =() =>
  {
    setPatientValue(null); 
    setDescriptionValue(""); 
    setEmployeeValue(null); 
    setIsNewPatient();
    setEventColor("#7171FF");
    setappointment({
      title: "",
      start: "",
      end: "",
      department_id: authState.department_id
    });
  }


  const handleOk = async ()=>{
    setLoading(true)
    setIsModalVisible(false);
    const patietName = ((patientValue === "[กรอกเอง]" )?  isNewPatient : patientValue )
    await axios.post(process.env.REACT_APP_API_URL+'/appointment', {...appointment,descripton: descriptionValue, patient_id: patietName, employee_id: employeeValue, title:patietName ,color:eventColor || "7171FF"}, { headers:{accessToken: Cookies.get('accessToken'),},})
    ClearData()
    const response = await axios.get(process.env.REACT_APP_API_URL + `/appointment/byId/${authState.department_id}`,{ headers:{accessToken: Cookies.get('accessToken'),},})
    getLastUpdatedAt()
    setEvents(response.data);
    setLoading(false)

  }

  const handleCancel = () => {
    setIsModalVisible(false);
    ClearData()
    setIsNewPatient();
  };
  
  const handleCancel1 =()=>{
    setIsModalVisible1(false);
    setIsEdit(false)
    setIsNewPatient();
    setPatientValue();
  }




  const onChange = (value, dateString) => {
    const selectedDatestr = new Date(dateString[0]); 
    const selectedDateend = new Date(dateString[1]); 

    const formattedDatestr = selectedDatestr.toISOString(); 
    const formattedDateend = selectedDateend.toISOString();
    setappointment({
      ...appointment,
      start: formattedDatestr,
      end: formattedDateend
    });
};

  const handleRecieve = (info) =>{
    if (authState.department_id === 0) {
      editcancel();
      return;
    }
    setTest({id: info.event.id, start:info.event.startStr, end:info.event.endStr ,employee_id:parseInt(info.event._def.resourceIds[0]) , resourceId:parseInt(info.event._def.resourceIds[0])})
    setIsEditModalOpen(true);
  }

  const editconfirm = async(info) =>{
    setLoading(true)
    await axios.put(process.env.REACT_APP_API_URL+'/appointment/update', test, { headers:{accessToken: Cookies.get('accessToken'),},})
    const response = await axios.get(process.env.REACT_APP_API_URL + `/appointment/byId/${authState.department_id}`,{ headers:{accessToken: Cookies.get('accessToken'),},})
    getLastUpdatedAt();
    setEvents(response.data);
    setIsEditModalOpen(false);
    setLoading(false)
  }

  const editcancel = async ()=>
  {
    setLoading(true)
    setIsEditModalOpen(false);
    const response = await axios.get(process.env.REACT_APP_API_URL + `/appointment/byId/${authState.department_id}`,{ headers:{accessToken: Cookies.get('accessToken'),},})
    setEvents(response.data);
    setLoading(false)
  }






const handleDelete = async (appointmentId) => {
  try {
    setLoading(true)
    const response = await axios.delete(process.env.REACT_APP_API_URL + `/appointment/${appointmentId}`, { headers:{accessToken: Cookies.get('accessToken'),},});
    if (response.status === 200) {
      setIsModalVisible1(false);

      const response = await axios.get(process.env.REACT_APP_API_URL + `/appointment/byId/${authState.department_id}`, { headers:{accessToken: Cookies.get('accessToken'),},})
      getLastUpdatedAt();
      setEvents(response.data);
      setLoading(false)
    } else {
    }
  } catch (error) {
    setLoading(false)
  }
};

const handleEdit = () => {
  setIsEdit(true);
  const inPatietnOptions = patientoptions.some((item)=> item.label === Eventdetail.title)
  const data = {
    name: inPatietnOptions ? Eventdetail.title : "[กรอกเอง]",
    nameCus : inPatietnOptions ? null : Eventdetail.title,
    descripton: Eventdetail.descripton || " ",
    employee_id: Eventdetail.employee_id.toString(),
    color: Eventdetail.color || "#7171FF"
  };
  console.log(data)
  setPatientValue(inPatietnOptions ? Eventdetail.title : "[กรอกเอง]")
  form.setFieldsValue(data);
  
};

const handleEditSave =async() =>
{
  setLoading(true)
  const data = form.getFieldsValue();
  console.log(data)
  const patientName = patients.find(item => item.id === parseInt(data.name) || item.name === data.name)?.name
  const employeeData = employee.find((emp) => emp.id === parseInt(data.employee_id))
  console.log(data)
  const transformData= {
    id: Eventdetail.id, title:(patientName? patientName:data.nameCus) || " ", descripton:data.descripton, employee_id: data.employee_id , resourceId:data.employee_id, 
    
    color: data.color && typeof data.color === 'object' && typeof data.color.toHexString === 'function'
    ? data.color.toHexString()
    : data.color || "#7171FF"
  
  };
  
  await axios.put(process.env.REACT_APP_API_URL+'/appointment/update', transformData, { headers:{accessToken: Cookies.get('accessToken'),},})
  const response = await axios.get(process.env.REACT_APP_API_URL + `/appointment/byId/${authState.department_id}`,{ headers:{accessToken: Cookies.get('accessToken'),},})
  setEventdetail({...Eventdetail,descripton:data.descripton, title:patientName? patientName:data.nameCus ,employee_name:employeeData.name, employee_id:data.employee_id ,color: data.color && typeof data.color === 'object' && typeof data.color.toHexString === 'function'
  ? data.color.toHexString()
  : data.color || "#7171FF"})
  getLastUpdatedAt();
  setEvents(response.data);
  setIsEdit(false)  
  setLoading(false)
}



  const renderDayHeader = (info) => {
    const dayName = formatDate(info.date, {
      locale: 'th',
      weekday: 'long'
    });
    return (
      <div>
        <div>{dayName}</div>
      </div>
    );
  };








  return (
    <div>
      <div className='flex justify-center items-center m-5'>
          <img src={Secheduler} alt="logo" className='md:w-[5rem] w-[2rem] '/>
          <div className='md:text-[3rem] text-[2rem] '>รายชื่อคนไข้</div>
      </div>
      <div className='md:p-[24px] md:bg-[#E77B7C] md:shadow-2xl md:w-[80%] md:mx-auto mx-[1rem] rounded-xl'>
      <div className='bg-white p-[1rem] rounded-xl'>
      <div className="text-left pb-[1rem]">แก้ไข้ครั้งสุดท้ายเมื่อ : {lastUpdatedDate}</div>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin,scrollGridPlugin]} // Add the dayGridPlugin here if needed
          initialView={'dayGridMonth'}
          resources={resource}
          aspectRatio={window.innerWidth < 768 ? '1': '2.6'}
          expandRows={true}
          eventLimit={true}
          dayHeaderContent={renderDayHeader}
          datesSet={handleViewChange} 
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'resourceTimeGridDay,dayGridMonth',
          }}
          views={{
            dayGridMonth: { titleFormat: { year: 'numeric', month: 'long' } },
            timeGridWeek: { titleFormat: { year: 'numeric', month: 'long', day: 'numeric' } },
            resourceTimeGridDay: { titleFormat: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' } }
          }}
          buttonText={{
            today: 'Today',  
            month: 'Month',  
            week: 'Week',    
            resourceTimeGridDay:'Day'      
          }}

          slotMinTime="09:00:00"
          slotMaxTime="20:00:00"
          selectable={true}
          locale={thLocal}
          eventClick={handleClick}
          select={handleSelect}
          events={events}
          editable={true}

          eventTimeFormat={{ 
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false}}
          
          dayMaxEvents={2}
          eventMaxStack={2}
          
          eventChange={handleRecieve}
          schedulerLicenseKey={'CC-Attribution-NonCommercial-NoDerivatives'}
          allDaySlot={false}
          dayMinWidth={window.innerWidth < 768 ? 45: 150}
          
        />

            



        <Modal title="เพิ่มข้อมูลตารางนัด" open={isModalOpen} onCancel={handleCancel} footer={[<Button key="ok" onClick={handleOk} disabled={!isFormValid}>ตกลง</Button>,<Button key="close" onClick={handleCancel}>ปิด</Button>,  ]}>
          <div style={{display:"flex" , flexDirection:"column" }   }>
          <labe>ชื่อผู้ป่วย</labe>
          <Select options={patientoptions} style={{width: "100%"}} showSearch filterOption={(input, option) => (option?.label ?? '').includes(input)} placeholder="ชื่อผู้ป่วย" onChange={(value, option) => setPatientValue(option?.label || null)} value={patientValue} getOptionLabel={(option) => option.label}/>
          {patientValue === "[กรอกเอง]" &&
          (
            <>
            <labe>ชื่อผู้ป่วย[กรอกเอง]</labe>
            <Input onChange={(e) => {setIsNewPatient(e.target.value || null); }}></Input>
            </>
          )
          }
          <labe>รายละเอียด</labe>
          <TextArea rows={4} placeholder="รายละเอียด" onChange={(e) => setDescriptionValue(e.target.value)} value={descriptionValue}/>
          <labe>ชื่อผู้รักษา</labe>
          <Select options={options} style={{width: "100%"}} showSearch filterOption={(input, option) => (option?.label ?? '').includes(input)} placeholder="นักกายภาพบำบัด" onChange={setEmployeeValue} value={appointment.resourceId} disabled/>
          <labe>สี</labe>
          <ColorPicker value={eventColor} presets={[{label:"พื้นฐาน", colors:[
                  '#7171FF',
                  '#FD6161', 
                  '#6CFF6C', 
                  '#FFFF78', 
                  '#FF75FF',
                  '#19BCBC', 
                  '#FFCC6E',
                  '#4F0E76', 
                  '#109C10', 
                  '#C4C4C4' 
                ] }]}
                onChange={(e)=>{setEventColor(e.toHexString())}}
                ></ColorPicker>
          <labe>เวลานัดหมาย</labe>
          <RangePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" onChange={onChange}  value={[dayjs(appointment.start), dayjs(appointment.end)]} disabledHours={ () => [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23] }/>
          </div>
        </Modal>


        <Modal
          title="รายละเอียด"
          open={isModalOpen1}
          onCancel={handleCancel1}
          footer={[
            <Button key="close" onClick={handleCancel1}>ปิด</Button>,
            !isEdit   & !(authState.department_id === 0)? <Button key="delete" onClick={() => handleDelete(Eventdetail.id)}>ลบ</Button>: null,
            !isEdit  & !(authState.department_id === 0) ?<Button key="Edit" onClick={handleEdit}>แก้ไข้</Button> : null,
            isEdit & !(authState.department_id === 0) ? <Button key="Save" onClick={handleEditSave}>บันทึก</Button> : null
          ]}
        >
          {Eventdetail && !isEdit && (
            <div>
              <p>ชื่อผู้ป่วย:  {Eventdetail.title}  </p>
              <p>รายละเอียด: {Eventdetail.descripton}</p>
              <p>ผู้ทำการรักษา: {Eventdetail.employee_name}</p>
            </div>
          )}

            {isEdit && (
            <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
              <Form.Item
                name="name"
                label="ชื่อผู้ป่วย"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
              <Select options={patientoptions} style={{width: "100%"}} showSearch filterOption={(input, option) => (option?.label ?? '').includes(input)} placeholder="ชื่อผู้ป่วย" onChange={(value, option) => setPatientValue(option?.label || null)} value={patientValue} getOptionLabel={(option) => option.label}/>
              
              </Form.Item>
              {patientValue === "[กรอกเอง]"
              &&
              (
                <Form.Item
                  name="nameCus"
                  label="ชื่อผู้ป่วย[กรอกเอง]"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  
                  <Input />
                  </Form.Item>
              )
              }
              <Form.Item
                name="descripton"
                label="คำอธิบาย"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                
                <TextArea />
                </Form.Item>
                <Form.Item
                name="employee_id"
                label="ผู้ทำการรักษา"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                
                <Select
                  placeholder="Select"
                  options={options}
                />
                </Form.Item>

                <Form.Item
                name="color"
                label="สี"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                
                <ColorPicker presets={[{label:"พื้นฐาน", colors:[
                  '#7171FF',
                  '#FD6161', 
                  '#6CFF6C', 
                  '#FFFF78', 
                  '#FF75FF',
                  '#19BCBC', 
                  '#FFCC6E',
                  '#4F0E76', 
                  '#109C10', 
                  '#C4C4C4' 
                ] }]}
                />
                </Form.Item>
              </Form>
              )}
        </Modal>


        <Modal title="Confirm edit" open={isEditModalOpen} onCancel={editcancel} footer={[<Button key="ok" onClick={editconfirm}>ตกลง</Button>,<Button key="close" onClick={editcancel}>ปิด</Button>]}>
            <div>
              <h1>กรุณายืนยันการเปลี่ยนแปลง</h1>
            </div>
        </Modal>





      </div>
      </div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

export default MainSchedule