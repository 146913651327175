import React, { useEffect, useState , useContext } from 'react';
import { Table ,  Popover} from 'antd';
import axios from 'axios';
import Cookies from 'js-cookie';
import {css} from '@emotion/css'
import { useParams } from 'react-router-dom';
import { AuthContext } from '../helpers/AuthContex';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

axios.defaults.headers.common['accessToken'] = Cookies.get('accessToken');
axios.defaults.headers.post["Content-Type"] = "application/json";
const transformData = (inputData) => {
    const rowData = [];
  
    inputData.forEach((item) => {
      const existingDate = rowData.find((data) => data.date === item.date);
  
      if (existingDate) {
        existingDate.employee.push({
          name: item.employee_name,
          onepercent: item.onepercent,
          twopercent: item.twopercent,
          threepercent: item.threepercent,
          fourpercent: item.fourpercent,
        });
      } else {
        rowData.push({
          date: item.date,
          employee: [
            {
              name: item.employee_name,
              onepercent: item.onepercent,
              twopercent: item.twopercent,
              threepercent: item.threepercent,
              fourpercent: item.fourpercent,
            },
          ],
        });
      }
    });
  
    return rowData;
  };

  const generateDateRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    let currentDate = startDate;
    const dates = [];
  
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return dates.map(date => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
      const year = date.getFullYear();
      return `${day}/${month}/${year}`; // format date as 'dd/mm/yyyy'
    });
  };

const Comission = () => {
  const { id } = useParams();
  
  const {authState} = useContext(AuthContext)

  const [employee, setEmployee] = useState();
  const [comission, setComssion] = useState();
  const [optimizeData, setOptimizeData] = useState();

 
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchdata = async () => {
      const employee = await axios.get(process.env.REACT_APP_API_URL + `/employee/byId/${authState.department_id}`, { headers:{accessToken: Cookies.get('accessToken'),},});
      setEmployee(employee.data);
      const comission = await axios.get(process.env.REACT_APP_API_URL+ `/comission/get-comission/byDp&Date/${authState.department_id}/${id}`, { headers:{accessToken: Cookies.get('accessToken'),},});
      setComssion(comission.data);
  
      const comissionWithName = comission.data.map((item) => {
        const employees = employee.data.find((e) => e.id === parseInt(item.employee_name));
        return { ...item, employee_name:employees?.name };
      });

      const [month,year] = id.split('-');
      const startDate = `${year}-${month}-01`;
      const endDate = new Date(year, month, 0).getDate();
      const allDates = generateDateRange(startDate, `${year}-${month}-${endDate}`);
      const newAllDates = [...allDates,'29/02/2567']
      const mergedData = newAllDates.map(date => {
        const existingData = transformData(comissionWithName).find(item => item.date === date);
        return existingData ? existingData : { date, employee: [] };
      });
      setOptimizeData(mergedData);
      setLoading(false)
    };
    fetchdata();
  }, []);

  const employeeDataMap = {};
  employee?.forEach((item) => {
    employeeDataMap[item.name] = item;
  });



  const tableData = Array.isArray(optimizeData) ? optimizeData.map((row) => {
    const tableRow = {
      key: row.date,
      date: row.date,
    };
    employee?.forEach((item) => {
      tableRow[`${item.name}_1percent`] = "0";
      tableRow[`${item.name}_2percent`] = "0";
      tableRow[`${item.name}_3percent`] = "0";
      tableRow[`${item.name}_4percent`] = "0";
    });

    row.employee.forEach((employeeEntry) => {
      const employeeName = employeeEntry.name;
      const employeeData = employeeDataMap[employeeName];
      if (employeeData) {
        tableRow[`${employeeName}_1percent`] = employeeEntry.onepercent || "0";
        tableRow[`${employeeName}_2percent`] = employeeEntry.twopercent || "0";
        tableRow[`${employeeName}_3percent`] = employeeEntry.threepercent || "0";
        tableRow[`${employeeName}_4percent`] = employeeEntry.fourpercent || "0";
      }
    });

    return tableRow;
    }) : [];


  const summaryRow = {
    key: 'summary',
    date: 'รวม',
  };

  const summaryPercentRow = 
  {
    key: 'summaryPercentRow',
    date:'รวม%',
  }

    employee?.forEach((item) => {
    const percentageColumns = ['_1percent', '_2percent', '_3percent','_4percent'];
    let sum = 0;
    let sumPercent = 0;
    percentageColumns.forEach((percentage) => {
      tableData.forEach((rowData) => {
        sum += parseFloat(rowData[`${item.name}${percentage}`])  || 0;
        sumPercent += parseFloat(rowData[`${item.name}${percentage}`]) || 0;
      });
      summaryRow[`${item.name}${percentage}`] = sum.toFixed(2);
      summaryPercentRow[`${item.name}${percentage}`] = (sumPercent * (percentage === '_1percent' ? 1/100 :  percentage === '_2percent' ? 2/100 :percentage === '_3percent' ?3/100 : 4/100)).toFixed(2);
      sum = 0; 
      sumPercent = 0;
    });
  });


  const columns = [
    {
      title: 'วันที่',
      dataIndex: 'date',
      key: 'date',
      fixed:'left',
      width:'6%',
      align:'center'
    },

    {
      title: 'รายชื่อพนักงาน',
      children: employee?.map((item) => ({
        title :
        ( 
          <Popover placement="top" className='cursor-pointer' content={
            (
              <>
            <div>
              ชื่อ : {item.name}
            </div>
            <div>
            ชื่อเล่น : {item.nickname}
          </div>
          <div>
              ตำแหน่ง : {item.role}
            </div>
            <div>
            ค่าหัต : {item.handfee}
          </div>
      </>
            )
          }
            >
          ({item.nickname})
          </Popover>
        ),
        children: [
          {
            title: '1%',
            dataIndex: `${item.name}_1percent`,
            key: `${item.name}_1percent`,
            render: (text) => parseFloat(text).toLocaleString(),
          },
          {
            title: '2%',
            dataIndex: `${item.name}_2percent`,
            key: `${item.name}_2percent`,
            render: (text) => parseFloat(text).toLocaleString(),
          },
          {
            title: '3%',
            dataIndex: `${item.name}_3percent`,
            key: `${item.name}_3percent`,
            render: (text) => parseFloat(text).toLocaleString(),
          },
          {
            title: '4%',
            dataIndex: `${item.name}_4percent`,
            key: `${item.name}_4percent`,
            render: (text) => parseFloat(text).toLocaleString(),
          },
        ],
      })),
    },
  ];

  const tableCSS = css({
    backgroundColor: 'white',
    '& table': {
      borderCollapse: 'collapse'
    },
    '& thead > tr': {
      borderWidth: '1px',
      borderColor: 'black',
      borderStyle: 'solid',
      backgroundColor: "blue"
    },
    '.ant-table-thead .ant-table-cell':
    {
      backgroundColor: '#00BA9E',
      color: 'white',
      borderRadius: 'unset !important'
    },".ant-table-cell .ant-table-cell-row-hover": {
      backgroundColor: 'red !important'
    },
    "&& tbody > tr:hover > td": {
      background: "grey !important"
    }
    
  });
  if (isLoading)
    {
      return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
      <CircularProgress color="inherit" />
      </Backdrop>
     ;
    }

  return (
    <>
      <div className='flex items-end justify-center mt-2'>
            <h5 className='md:text-[3rem] text-[2rem] mb-[10px] '>สรุปค่าแนะนำ</h5>
      </div>
      <div className='md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl  mx-[0.5rem] rounded-xl ' >
          <div className={`md:px-[24px] px-[0.5rem] pt-[24px] bg-white shadow-2xl rounded-xl pb-[24px] `}>
          <Table columns={columns} dataSource={[...tableData, summaryRow, summaryPercentRow]} rowKey="key" size="small" className={tableCSS} pagination={false} scroll={{ x: 'max-content', y: window.innerWidth < 786 ? 500 : 600 }} />
        </div>
      </div>
    </>
  );
};

export default Comission;
