import React, {useEffect, useState, useContext, useRef} from 'react'
import axios from 'axios';
import Cookies from 'js-cookie';

import { json2csv } from 'json-2-csv';
import {Modal , Button, Input, Select,Table, Form , InputNumber ,Statistic , message, Badge ,Radio,Checkbox ,DatePicker} from 'antd'

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { AuthContext } from '../helpers/AuthContex';

import dayjs from 'dayjs';
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
const bangkokTimezone = "Asia/Bangkok"

const AccountPage = () => {
    const [form] = Form.useForm();
    const [reportType, setReportType] = React.useState(''); // State for report type
    const [selectedDate, setSelectedDate] = React.useState(null); // State for selected date
    const [isLoading, setIsLoading] = React.useState(false);

    const{authState} = useContext(AuthContext)
    const { RangePicker } = DatePicker;

    const getData = async (e) => {
      
      setIsLoading(true);
      const request_data = {
        reportType: e.report_type, 
        date: Array.isArray(e.date) ? "" : e.date.format("YYYY-MM-DD"), 
        startDate: Array.isArray(e.date) ? e.date[0].format("YYYY-MM-DD") : "",
        endDate: Array.isArray(e.date) ? e.date[1].format("YYYY-MM-DD") : "" 
      };

      const generateFileName = (reportType, requestData) => {
        switch (reportType) {
          case "DAILY":
            return `ตารางรับรู้รายได้วันที่${dayjs(requestData.date).format("DD-MM-YYYY")}.csv`;
          case "MONTHLY":
            return `ตารางรับรู้รายได้${dayjs(requestData.date).format("MM-YYYY")}.csv`;
          case "YEARLY":
            return `ตารางรับรู้รายได้${dayjs(requestData.date).format("YYYY")}.csv`;
          case "custom":
            return `ตารางรับรู้รายได้${dayjs(requestData.startDate).format("DD-MM-YYYY")}_ถึง_${dayjs(requestData.endDate).format("DD-MM-YYYY")}.csv`;
          default:
            return `ตารางรับรู้รายได้${dayjs(requestData.date).format("DD-MM-YYYY")}.csv`;
        }
      };
    
      const response = await axios.post(
        process.env.REACT_APP_API_URL + `/patient/user-course-left-over/${authState.department_id}`,
        request_data,
        { headers: { accessToken: Cookies.get('accessToken') } }
      );
      const data = response.data;
      const csv = await json2csv(data,{unwindArrays:true});
      const blob = new Blob([`\uFEFF${csv}`], { type: 'text/csv;charset=utf-8;' }); // Add BOM for UTF-8
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const fileName = `${generateFileName(reportType,request_data)}.csv`;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsLoading(false);
    };

    const handleReportTypeChange = (e) => {
        const dateType = { DAILY : "", MONTHLY: "month", YEARLY: "year" ,CUSTOM: "custom" }
        setReportType(dateType[e.target.value]);
        form.resetFields(['date']);
    };
    


  return (
    <>
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open={isLoading}>
            <CircularProgress color="inherit" />
    </Backdrop>
    <div className='flex gap-10 mt-10 justify-center flex-col items-center '> 
      <div className='flex items-end justify-center mt-2'>
            <h5 className='md:text-[3rem] text-[2rem] mb-[10px] '>รายงานบัญชี</h5>
      </div>
      <div className='md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl  mx-[0.5rem] rounded-xl ' >
        <div className={`md:px-[24px] px-[0.5rem] pt-[24px] bg-white shadow-2xl rounded-xl pb-[24px] `}>
            <Form name="basic"
              form={form}
              onFinish={getData}
              autoComplete="off"
              layout='vertical'
              className=' flex flex-col'
              initialValues={{report_type:"DAILY" }}
            >
                <Form.Item name="report_type" label="ประเภทรายงาน" style={{ marginBottom: "10px" }}>
                  <Radio.Group onChange={handleReportTypeChange}>
                    <Radio value="DAILY">รายวัน</Radio>
                    <Radio value="MONTHLY">รายเดือน</Radio>
                    <Radio value="YEARLY">รายปี</Radio>
                    <Radio value="CUSTOM">กำหนดเอง</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name='date' label="วันที่" style={{ marginBottom: "10px" ,marginLeft: "0px"}} rules={[{required: true,message: 'กรุณากรอกวันที่',},]}>
                  {reportType === "custom" ? (
                    <RangePicker />
                  ) : (
                    <DatePicker 
                      {...(reportType !== "" ? { picker: reportType } : {})}
                    />
                  )}
                </Form.Item>

              <Form.Item className='flex justify-center' >
                  <Button htmlType="submit">
                    ดาวโหลดไฟล์
                  </Button>
              </Form.Item>
              
            </Form>
        </div>
      </div>
    </div>
    </>
  )
}

export default AccountPage