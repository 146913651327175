import React, {useEffect, useState} from 'react'

import axios from 'axios';
import Cookies from 'js-cookie';
import {Modal , Button ,Table,Form ,Radio,Input, InputNumber} from 'antd'


import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';

import { DownloadOutlined } from '@ant-design/icons';
import { Worker ,Viewer} from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import MyDocument from '../pages/pdf';
import MyDocument2 from '../pages/initpdf';
import { pdf} from '@react-pdf/renderer';
import {css} from '@emotion/css'

import { formatNumberWithCommas } from '../utils/Utils';


function formatDate(inputDate) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const months = [
      "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
      "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม",
      "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
  ];
  const month = months[date.getMonth()];
  const year = date.getFullYear() + 543;
  const hour = date.getHours();
  const minute = date.getMinutes();
  const formattedHour = (hour < 10 ? '0' : '') + hour;
  const formattedMinute = (minute < 10 ? '0' : '') + minute;
  const formattedDate = `${day} ${month} ${year} ${formattedHour}:${formattedMinute}`;
  return formattedDate;
}


const Billhistory = ({patient_data}) => {
    const {id} = useParams()

    const [bill, setBill] = useState([])

    const [billItem, setBillitem] = useState([])

    const [order, setOrders] = useState([])

    const [service, setServices] = useState([])

    const [billshow , setBillshow] = useState([])

    const [billItemshow, setBillitemshow] = useState([])

    const [billdata ,setBilldata] = useState([])

    const [courseItem, setCourseItem] = useState([])

    const [isModalOpen, setIsModalopen] = useState(false)
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [pdfDataUrl, setPdfDataUrl] = useState('blob:http://localhost:3000/2fa78e5f-a484-4359-97b4-a72405780a25');

    const [patientData, setPatientData] = useState()
    const [listOfDepartment, setListOfdepartment] = useState();
    const [isLoading, setLoading] = useState(true);
    const [isPaymentEditModalOpen, setIsPaymentEditModalCancle] = useState(false);

    const [form] = Form.useForm();

    

    function transformData(data, item1 ,item2) {
      const newData = data.map(billItem => {
          let matchingItem1 = null;
          matchingItem1 = item1.find(item => item.id === parseInt(billItem.order_id));
          return {
                  key:billItem.id,
                  order_id:billItem.order_id,
                  item_id:matchingItem1.item_id,
                  item_amount: matchingItem1.item_times,
                  type: matchingItem1.type,
                  item_totalprice:matchingItem1.item_totalprice,
                  price_to_paid:billItem.price_to_paid,
                  billId:billItem.billId,
                  discount_value:billItem.discount_value,
                  remain_price:billItem.remain_price,
                  employee_id:billItem.employee_id,
                  times_used:billItem.times_used,
                  inviter_id:matchingItem1.inviter_id,
                  employee_list: billItem.employee_list,
                  handfee: billItem.handfee
          };
      });

      const newData2 = newData.map(billItem =>{
          let matchingItem2 = null;
          matchingItem2 = item2.find(item => item.service_id === billItem.item_id)
          return{
              ...billItem,
              item_name:matchingItem2.service_name,
              item_price:matchingItem2.service_price
          }
      });

      return newData2;
  }

      const fetchdata = async ()=> {
        const billsresponse =  await axios.get(process.env.REACT_APP_API_URL + `/course/bill/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
        setBill(billsresponse.data)
        const billitemresponse =  await axios.get(process.env.REACT_APP_API_URL + `/course/billitem/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
        setBillitem(billitemresponse.data)
        const list_of_orders = await axios.get(process.env.REACT_APP_API_URL + `/course/patientorder/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
        setOrders(list_of_orders.data)
        const services = await axios.get(process.env.REACT_APP_API_URL + `/course/service`,{ headers:{accessToken: Cookies.get('accessToken'),}})
        setServices(services.data)

        const coruseItem = await axios.get(process.env.REACT_APP_API_URL + `/course/courseItem`,{ headers:{accessToken: Cookies.get('accessToken'),}})
        setCourseItem(coruseItem.data)

        const department = await axios.get(process.env.REACT_APP_API_URL + `/patient/clinic`, { headers:{accessToken: Cookies.get('accessToken'),},})
        setListOfdepartment(department.data)

        const newData = billsresponse.data.map(item=> ({...item, key: item.id }))
        setBill(newData)

        const newData2 = transformData(billitemresponse.data,list_of_orders.data,services.data)

        setBillitemshow(newData2)
        setLoading(false)
    }

    useEffect(() => {
        fetchdata();
    }, []);


    const handlePaymentEditModalCancle = () =>
    {
      setIsPaymentEditModalCancle(false);
      form.resetFields();
    }

    const handlePaymentEditModalOpen = (record) =>
    {
      setIsPaymentEditModalCancle(true)
      form.setFieldValue('payment_method', record.payment_method);
      form.setFieldValue('id', record.id);
    }

    const handlePaymetnEditFinish = async()=>
    {
      setLoading(true)
      const data = form.getFieldsValue();
      const response = await axios.put(process.env.REACT_APP_API_URL + '/course/bills/payment-method-edit' ,data ,{ headers:{accessToken: Cookies.get('accessToken'),}})
      await fetchdata();
      handlePaymentEditModalCancle();
    }



      const expandedRowRender = (record) => {
        const filteredBillItems = billItemshow.filter((item) => item.billId === record.id);

        const ordercolumn = [
            {
              title: 'Order Id',
              dataIndex: 'order_id',
              key:'order_id'
            },
            {
                title: 'ชื่อ',
                dataIndex: 'item_name',
                key:'item_name'
            },
            {
                title: 'จำนวนครั้ง',
                dataIndex: 'item_amount',
                key:'item_amount',
            },
            {
                title: 'ราคาต่อหน่วย',
                dataIndex: 'item_price',
                key:'item_price',
                render:(item_price) =>{
                  return formatNumberWithCommas(item_price)
                } 
            },
            {
              title: 'ราคารวม',
              dataIndex: 'item_totalprice',
              key:'item_totalprice',
              render:(item_totalprice) =>{
                return formatNumberWithCommas(item_totalprice)
              } 
            },
            {
              title: 'ราคาจ่าย',
              dataIndex: 'price_to_paid',
              key:'price_to_paid',
              render:(price_to_paid) =>{
                return formatNumberWithCommas(price_to_paid)
              } 
            },
            {
              title: 'รหัสผู้ทำการรักษา',
              dataIndex: 'employee_list',
              key: 'employee_list',
              width: '10%',
              render(_, record, index) {
                if (record.employee_list?.length === 0 ) {
                  return <span>ไม่มี</span>;
                }
                return record.employee_list?.map((employee, employeeIndex) => (
                  <span key={employeeIndex}>
                    {employee === 0  ? "-" : employee === -1 ? "-" : employee}
                    {employeeIndex !== record.employee_list.length - 1 && ','}
                  </span>
                ));
              }
            },
            {
              title: 'จำนวนค่ามือ',
              dataIndex: 'handfee',
              key: 'handfee',
              width: '10%',
              render(_, record, index) {
                if (record.employee_list?.length === 0 ) {
                  return <span>ไม่มี</span>;
                }
                return record.employee_list?.map((employee, employeeIndex) => (
                  <span key={employeeIndex}>
                    {employee === 0  ? "-" : employee === -1 ? "-" : record.handfee}
                    {employeeIndex !== record.employee_list.length - 1 && ','}
                  </span>
                ));
              }
            },
            {
              title: 'ประเภท',
              dataIndex: 'type',
              key:'type',
              render: (type) => {
                if (type === 1) {
                  return <span>บริการ</span>;
                } else if (type === 2) {
                  return <span>คอร์สต์</span>;
                }
                return null; 
              },
            },
          ]

        return <Table columns={ordercolumn} dataSource={filteredBillItems} pagination={false} className={CollapsetableCSS} />;
      };

      const handleModalCancle = () =>
      {
        setIsModalopen(false)
      }

      const handleModalOpen = async (record,billdata,bill_type, department) =>
      {
        setIsModalopen(true);

          const response =  await axios.get(process.env.REACT_APP_API_URL + `/patient/byId/${record.patient_id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
              setPatientData(response.data)
          try {
            const blob = await pdf(
                  <MyDocument order={billdata} patient_data={response.data} type={2} date={formatDate(record.createdAt)} billid={record.id} service={service} courseItem={courseItem} department={department} additional_discount={record.additional_discount} remark={record.remark}/>
              ).toBlob({ quality: 1 });
            
            const url = URL.createObjectURL(blob);
            setPdfDataUrl(url.toString())
          } catch (error) {
            console.error('Error generating PDF:', error);
          }

      }
      const generatePDF = async (record,billdata,bill_type,department) => { 
        try {
          const response =  await axios.get(process.env.REACT_APP_API_URL + `/patient/byId/${record.patient_id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
              setPatientData(response.data)
          const blob = await pdf(
                <MyDocument order={billdata} patient_data={response.data} type={2} date={formatDate(record.createdAt)} billid={record.id} service={service} courseItem={courseItem} department={department} additional_discount={record.additional_discount} remark={record.remark}/>
            ).toBlob({ quality: 1 });
          
          const url = URL.createObjectURL(blob);
          
          // Create a temporary anchor element to trigger the download
          const a = document.createElement('a');
          a.href = url;
          a.download = `bill_${record.id}.pdf`; // Set the desired filename
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          
          // Remove the temporary anchor element
          document.body.removeChild(a);
          
          // Clean up the URL object
          URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      };

      const tableCSS = css({
        backgroundColor: 'white',
        '& table': {
          borderCollapse: 'collapse'
        },
        '& thead > tr': {
          borderWidth: '1px',
          borderColor: 'black',
          borderStyle: 'solid',
          backgroundColor: "blue"
        },
        '.ant-table-thead .ant-table-cell':
        {
          backgroundColor: '#00BA9E',
          color: 'white',
          borderRadius: 'unset !important'
        }
        
      });

      const CollapsetableCSS = css({
        backgroundColor: 'white',
        '& table': {
          borderCollapse: 'collapse'
        },
        '& thead > tr': {
          borderWidth: '1px',
          borderColor: 'grey',
          borderStyle: 'solid',
          backgroundColor: "blue"
        },
        '.ant-table-thead .ant-table-cell':
        {
          backgroundColor: 'grey',
          color: 'white',
          borderRadius: 'unset !important',
        },
        
      });
      


      const columns = [
        {
          title: 'BILL ID',
          dataIndex: 'id',
          key: 'id',
          width:'5%',
          align:'center'
        },
        {
          title: 'Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render : (createdAt) =>
          {
            return formatDate(createdAt);
          }
        },
        {
          title: 'จำนวนเงิน',
          dataIndex: 'totoalvalue',
          key: 'totoalvalue',
          width:'5%',
          align:'center',
          render:(totoalvalue) =>{
            return formatNumberWithCommas(totoalvalue)
          } 
        },   
        {
          title: 'วิธีจ่ายเงิน',
          dataIndex: 'payment_method',
          key: 'payment_method',
          width:'5%',
          render : (_,record) =>
          {
            return(
              <div onClick={()=>{handlePaymentEditModalOpen(record)}} className='text-blue-400 hover:underline hover:cursor-pointer active:text-green-200 select-none duration-75 '>{record.payment_method}</div>
            )
          }
        },
        {
          title: 'ประเภทบิล',
          dataIndex: 'bill_type',
          key: 'bill_type',
          width:'5%',
          align:'center',
          render: (text, record) => {
            return record.bill_type === 1 ? 
            <div>บิลใหม่</div> : 
            <div>ตัดบิล</div>;
          }
        },   
        {
          title: 'จัดการ',
          key: 'operation',
          align:'center',
          width:'10%',
          render: (text, record) => {
            const filteredBillItems = billItemshow.filter((item) => item.billId === record.id);
            const filterDepartment = listOfDepartment?.find((item) => item.id === parseInt(record.department_id))
            const billdata = filteredBillItems.map((item) => ({
              id: item.order_id,
              item_id: item.item_id,
              name: item.item_name,
              amount: item.item_amount,
              price: item.item_price,
              discount: item.discount_value,
              type: item.type,
              totalPrice: item.price_to_paid,
              remain: item.remain_price,
              employee_id: item.employee_id,
              times_used : item.times_used,
              inviter_id:item.inviter_id,
              employee_list: item.employee_list
          }));
            return(
              <div className='flex'>
              <Button icon={<DownloadOutlined />} onClick={()=>{generatePDF(record,billdata,record.bill_type ,filterDepartment , record.additional_discount)}}>โหลดPDF</Button>
              <Button icon={<DownloadOutlined />} onClick={()=>{handleModalOpen(record,billdata,record.bill_type, filterDepartment , record.additional_discount)}}>เปิดดู PDF</Button>
              </div>
            )
          },
        },
      ];

      if (isLoading)
      {
        return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
        <CircularProgress color="inherit" />
        </Backdrop>
       ;
      }
      return (
        <>
        <div className='md:p-[24px]  md:bg-[#E77B7C] shadow-md md:shadow-2xl md:mx-[10rem] mx-[0.5rem] rounded-xl mb-10' >
          <div className={`md:px-[24px] px-[0.5rem] pt-[24px] bg-white md:shadow-2xl rounded-xl ${(billItemshow.length === 0 ) ? 'pb-[24px]' : null}`}>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender,
              defaultExpandedRowKeys: ['0'],
            }}
            dataSource={bill.reverse()}
            size={"small"}
            className={tableCSS}
            scroll={{x:'max-content'}}
          />
          </div>
          </div>
          
          <Modal width={1000} title="ออกบิล" open={isModalOpen} onCancel={handleModalCancle} footer={[<Button key="ok" >ตกลง</Button>,<Button key="close" onClick={handleModalCancle} >ปิด</Button>]}>
          {/* <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
                <Viewer fileUrl={pdfDataUrl} plugins={[defaultLayoutPluginInstance]}/>
          </Worker> */}
          <embed src={pdfDataUrl} frameborder={"0"} width={"100%"} height={window.innerWidth < 786 ? "500px" : "1000px"}/>

          
        </Modal>

        <Modal  title="แก้ไข้วิธีชำระเงิน" open={isPaymentEditModalOpen} onCancel={handlePaymentEditModalCancle} footer={false}>
            
            <Form form={form} onFinish={handlePaymetnEditFinish}>
            <Form.Item  style={{ marginBottom: "10px" }}
                      label="รหัสใบเสร็จ"
                      name="id"
                      rules={[
                        {
                          required: true,
                          message: 'กรุณาใส่รหัสบิล',
                        },
                      ]}
                    >
                    <InputNumber readOnly={true}></InputNumber>
                    
                    </Form.Item>
            <Form.Item  style={{ marginBottom: "10px" }}
                      label="วิธีการชำระเงิน"
                      name="payment_method"
                      rules={[
                        {
                          required: true,
                          message: 'กรุณาเลือกประเภท',
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="เงินสด"> เงินสด </Radio>
                        <Radio value="โอนธนาคาร"> โอนธนาคาร </Radio>
                        <Radio value="บัตรเครดิต/เดบิต"> บัตรเครดิต/เดบิต </Radio>
                        <Radio value="ไม่มี"> ไม่มี</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item style={{marginBottom:'0px',display:'flex',justifyContent:'center'}}
                    >
              <Button htmlType="submit" >
                ตกลง
              </Button>
              <Button onClick={handlePaymentEditModalCancle} >
                ยกเลิก
              </Button>
            </Form.Item>
            </Form>
        </Modal>
        </>
      );
}

export default Billhistory