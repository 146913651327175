import React, {useEffect, useState, useContext, useRef} from 'react'

import axios from 'axios';
import Cookies from 'js-cookie';
import { AuthContext } from '../helpers/AuthContex';
import {Input, Table, Form , message, Badge,Button ,Space} from 'antd'

import { formatNumberWithCommas } from '../utils/Utils';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';

import { SearchOutlined } from '@ant-design/icons';

import { css } from '@emotion/css';





const CourseHistory = ({patient_data}) => {

    

    const{authState} = useContext(AuthContext)
    const {id,department_id} = useParams()
    const [isLoading, setLoading] = useState(true);
    const [orders, setOrders] = useState([])
    const [services, setServices] = useState([])
    const [Modifiedorder, setModifiedorder] = useState([])
    const [option, setOptions] =useState([])
    const [employee, setemployee] = useState([]);
    const [employeeoption, setemployeeoption] =useState([]);
    const formRef = React.useRef(null);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [bills, setBills] = useState([])
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);


    useEffect(() => {
      const fetchdata = async ()=> {
        const list_of_orders = await axios.get(process.env.REACT_APP_API_URL + `/course/patientorder/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
        const filter_list_of_oders = list_of_orders.data.filter((item) => item.item_status === 3)
        setOrders(filter_list_of_oders);
        
        const services = await axios.get(process.env.REACT_APP_API_URL + `/course/service`,{ headers:{accessToken: Cookies.get('accessToken'),}})
        setServices(services.data);

        const employeeresponse = await axios.get(process.env.REACT_APP_API_URL + `/employee/byId/${authState.department_id}`,{ headers:{accessToken: Cookies.get('accessToken'),}});
        setemployee(employeeresponse.data);

        const newemployee = employeeresponse.data.map(item => ({value:item.id, label: item.name}))
        setemployeeoption(newemployee)

        const billitemresponse =  await axios.get(process.env.REACT_APP_API_URL + `/course/billitem/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
        setBills(billitemresponse.data)

        const combinedOptions = services.data.map(item => ({ value: item.service_id, label: item.service_name , type: item.service_type , price: item.service_price, disabled: false, }));
        setOptions(combinedOptions);

        

        const newData = filter_list_of_oders.map(ordersItem => {
          let matchingItem = null;
          matchingItem = services.data.find(item => item.service_id === ordersItem.item_id);
          return {
              key: ordersItem.id,
              id: ordersItem.id,
              item_name: matchingItem.service_name,
              item_times: ordersItem.item_times,
              type:ordersItem.type,
              item_paid:ordersItem.item_paid,
              item_status:ordersItem.item_status,
              orders_price: ordersItem.item_times*matchingItem.service_price,
              item_totalprice: ordersItem.item_totalprice,
              item_remain: ordersItem.item_remain,
              relize_revenue: ((ordersItem.item_times-ordersItem.item_remain)*(ordersItem.item_totalprice/ordersItem.item_times)).toFixed(2),
              inviter_id:ordersItem.inviter_id,
              discount_value: ordersItem.discount_value,
              discount_id: ordersItem.discount_id
            };
        
        });
        setModifiedorder(newData)
        setLoading(false)
      };

      fetchdata();
  }, []);

  const fetchdata = async ()=> {
    const list_of_orders = await axios.get(process.env.REACT_APP_API_URL + `/course/patientorder/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
    const filter_list_of_oders = list_of_orders.data.filter((item) => item.item_status === 3)
    setOrders(filter_list_of_oders);
    
    const services = await axios.get(process.env.REACT_APP_API_URL + `/course/service`,{ headers:{accessToken: Cookies.get('accessToken'),}})
    setServices(services.data);

    const employeeresponse = await axios.get(process.env.REACT_APP_API_URL + `/employee/byId/${authState.department_id}`,{ headers:{accessToken: Cookies.get('accessToken'),}});
    setemployee(employeeresponse.data);

    const newemployee = employeeresponse.data.map(item => ({value:item.id, label: item.name}))
    setemployeeoption(newemployee)

    const billitemresponse =  await axios.get(process.env.REACT_APP_API_URL + `/course/billitem/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
    setBills(billitemresponse.data)

    const combinedOptions = services.data.map(item => ({ value: item.service_id, label: item.service_name , type: item.service_type , price: item.service_price, disabled: false, }));
    setOptions(combinedOptions);

    

    const newData = filter_list_of_oders.map(ordersItem => {
      let matchingItem = null;
      matchingItem = services.data.find(item => item.service_id === ordersItem.item_id);
      return {
          key: ordersItem.id,
          id: ordersItem.id,
          item_name: matchingItem.service_name,
          item_times: ordersItem.item_times,
          type:ordersItem.type,
          item_paid:ordersItem.item_paid,
          item_status:ordersItem.item_status,
          orders_price: ordersItem.item_times*matchingItem.service_price,
          item_totalprice: ordersItem.item_totalprice,
          item_remain: ordersItem.item_remain,
          relize_revenue: ((ordersItem.item_times-ordersItem.item_remain)*(ordersItem.item_totalprice/ordersItem.item_times)).toFixed(2),
          inviter_id:ordersItem.inviter_id,
          discount_value: ordersItem.discount_value,
          discount_id: ordersItem.discount_id
        };
    
    });
    setModifiedorder(newData)
    setLoading(false)
  };


  const handleUnfinishedCourse = async (record) =>
  {
        setLoading(true)
        const updatadata = async () => {
          try {
                  const data = {
                    id: record.id,
                    item_status: 1,
                };
                  const response = await axios.put(process.env.REACT_APP_API_URL + `/course/update`,data,{ headers:{accessToken: Cookies.get('accessToken'),}})
          } catch (error) {
              console.error('Error creating departments:', error);
          }
        
          };
        await updatadata();
        await fetchdata();
        setLoading(false)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`ค้าหา ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className='bg-[#7877FF]'
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });
    if (isLoading)
    {
      return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
      <CircularProgress color="inherit" />
      </Backdrop>
     ;
    }

    const tableCSS = css({
      backgroundColor: 'white',
      '& table': {
        borderCollapse: 'collapse'
      },
      '& thead > tr': {
        borderWidth: '1px',
        borderColor: 'black',
        borderStyle: 'solid',
        backgroundColor: "blue"
      },
      '.ant-table-thead .ant-table-cell':
      {
        backgroundColor: '#00BA9E ',
        color: 'white ',
        borderRadius: 'unset !important '
      },
      '.ant-table-cell:hover':
        {
          cursor:'pointer'
        }
      
    });

    const billtableCSS = css({
      backgroundColor: 'white',
      '& table': {
        borderCollapse: 'collapse'
      },
      '& thead > tr': {
        borderWidth: '1px',
        borderColor: 'black',
        borderStyle: 'solid',
        backgroundColor: "blue"
      },
      '.ant-table-thead .ant-table-cell':
      {
        backgroundColor: 'grey',
        color: 'white ',
        borderRadius: 'unset'
      },
      '.ant-table-cell:hover':
        {
          cursor:'pointer'
        }
      
    });

    const expandedRowRender = (record) => {
      const filteredBillItems = bills.filter((item) => parseInt(item.order_id) === record.id);
      const selectedBill = Modifiedorder.find((item) => record.id === item.id)
      const modifiedData = filteredBillItems.map(item => {
        return{
          ...item,
          relize_revenue : item.times_used*(selectedBill.item_totalprice/selectedBill.item_times).toFixed(2)
        }}
      )
      const ordercolumn = [
          {
            title: 'ลำดับ',
            dataIndex: 'index',
            key:'index',
            width: '5%',
            render: (text, record, index) => index + 1,
          },
          {
              title: 'วันที่',
              dataIndex: 'createdAt',
              key:'createdAt',
              width: '30%'
          },
          {
            title: 'จำนวนที่ใช้',
            dataIndex: 'times_used',
            key:'times_used',
            width: '10%'
          },
          {
              title: 'ราคาที่จ่าย',
              dataIndex: 'price_to_paid',
              key:'price_to_paid',
              width: '10%',
              render:(price_to_paid) =>{
                return formatNumberWithCommas(price_to_paid)
              } 
          },
          {
            title: 'รายรับจริง',
            dataIndex: 'relize_revenue',
            key:'relize_revenue',
            width: '10%',
            render:(relize_revenue) =>{
              return formatNumberWithCommas(relize_revenue)
            } 
          },
          {
            title: 'ราคาคงเหลือ',
            dataIndex: 'remain_price',
            key:'remain_price',
            width: '10%',
            render:(remain_price) =>{
              return formatNumberWithCommas(remain_price)
            } 
          },
          {
            title: 'รหัสผู้ทำการรักษา',
            dataIndex: 'employee_list',
            key: 'employee_list',
            width: '10%',
            render(_, record, index) {
              if (record.employee_list?.length === 0 ) {
                return <span>ไม่มี</span>;
              }
              return record.employee_list?.map((employee, employeeIndex) => (
                <span key={employeeIndex}>
                  {employee === 0  ? "-" : employee === -1 ? "-" : employee}
                  {employeeIndex !== record.employee_list.length - 1 && ','}
                </span>
              ));
            }
          },
          {
            title: 'จำนวนค่ามือ',
            dataIndex: 'handfee',
            key: 'handfee',
            width: '10%',
            render(_, record, index) {
              if (record.employee_list?.length === 0 ) {
                return <span>ไม่มี</span>;
              }
              return record.employee_list?.map((employee, employeeIndex) => (
                <span key={employeeIndex}>
                  {employee === 0  ? "-" : employee === -1 ? "-" : record.handfee}
                  {employeeIndex !== record.employee_list.length - 1 && ','}
                </span>
              ));
            }
          },
        ]

      return <Table columns={ordercolumn} pagination={false} dataSource={modifiedData} className={billtableCSS} />;
    };

    const column = [
        {
          title: 'Order ID',
          dataIndex: 'id',
          key:'id',
          width: '5%',
            align:"center",
            ...getColumnSearchProps('id'),
      },
      {
          title: 'ชื่อ',
          dataIndex: 'item_name',
          key:'item_name',
          width: '20%',
          ...getColumnSearchProps('item_name'),
      },
      {
          title: 'จำนวนครั้ง',
          dataIndex: 'item_times',
          key:'item_times',
          width: '3.5%',
            align:"center"
          
      },
      {
        title: 'คงเหลือ',
        dataIndex: 'item_remain',
        key:'item_remain',
        width: '3.5%',
          align:"center"
    },
      
      {
          title: 'ราคา',
          dataIndex: 'item_totalprice',
          key:'item_totalprice',
          width: '7%',
          align:"center",
          render:(item_totalprice) =>{
            return formatNumberWithCommas(item_totalprice)
          } 
      },
      {
        title: 'ส่วนลด',
        dataIndex: 'discount_value',
        key:'discount_value',
        width: '7%',
        align:"center",
        render:(discount_value) =>{
          return formatNumberWithCommas(discount_value)
        } 
      },
      {
        title: 'ราคาที่จ่ายไปเเล้ว',
        dataIndex: 'item_paid',
        key:'item_paid',
        width: '7%',
        align:"center",
        render:(item_paid) =>{
          return formatNumberWithCommas(item_paid)
        } 
      },
      {
        title: 'รายรับจริง',
        dataIndex: 'relize_revenue',
        key:'relize_revenue',
        align:"center",
        render:(relize_revenue) =>{
          return formatNumberWithCommas(relize_revenue)
        } 
      },
      {
        title: 'รหัสผู้แนะนำ',
        dataIndex: 'inviter_id',
        key:'inviter_id',
        width: '7%',
            align:"center"
      },
      {
        title: 'ประเภท',
        dataIndex: 'type',
        key:'type',
        align:'center',
        render: (type) => {
          if (type === 1) {
            return <span>บริการ</span>;
          } else if (type === 2) {
            return <span>คอร์สต์</span>;
          }
          return null; 
        },
      },
      {
        title: 'สถานะ',
        dataIndex: 'item_status',
        key:'item_status',
        align:"center",
        render : () =>{
            return <Badge status="success" text="เสร็จสิ้นสมบูรณ์" />
        }
      },
      authState.role === 'admin'
      ? 
      {
        title: 'จัดการ',
        dataIndex: 'operation',
        align:'center',
        width: '12%',
        render: (_, record) =>
        {
          const finished = record.item_status === 3;
          return (
          <div className='flex gap-x-1 justify-center'>
            <Button className={`border-[1px] border-[#ff814f]  rounded-md px-1  ${finished ? 'bg-[#ffb34f] hover:translate-y-[-3px] duration-100 text-[#242424]' : 'bg-grey text-[#131212] '}`} onClick={()=>{handleUnfinishedCourse(record)}} disabled={!finished || authState.role !== "admin" }>ยกเลิก</Button>
          </div>
        )}
        ,
      } : null,
  ]




  return (
    <>    
    <div className='md:p-[24px]  md:bg-[#E77B7C] shadow-md md:shadow-2xl md:mx-[10rem] mx-[0.5rem] rounded-xl ' >
          <div className={`md:px-[24px] px-[0.5rem] pt-[24px] bg-white md:shadow-2xl rounded-xl ${(Modifiedorder.length === 0 ) ? 'pb-[24px]' : null}`}>
            <Table dataSource={Modifiedorder.reverse()} columns={column.filter(Boolean)} bordered className={tableCSS}  pagination={{ showPagination: 'always', pageSize: 6}} size="small" rowKey={"key"} 
            expandable={{
              expandedRowRender,
              defaultExpandedRowKeys: ['0'],
            }}
            scroll={{x: 'max-content'}}
            />
          </div>
    </div>

        
    </>
    
  )
}
export default CourseHistory