import React, {useEffect, useState, useContext, useRef} from 'react'

import axios from 'axios';
import { AuthContext } from '../helpers/AuthContex';
import {Modal , Button, Input, Select,Table, Form , InputNumber ,Statistic , message, Badge ,Radio,Checkbox ,DatePicker} from 'antd'

import Cookies from 'js-cookie';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';

import moment from 'moment'
import dayjs from 'dayjs';

import { css } from '@emotion/css';
import TextArea from 'antd/es/input/TextArea';

import { formatNumberWithCommas } from '../utils/Utils';


const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

const bangkokTimezone = "Asia/Bangkok"

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  setIsvalid,
  isValid,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setIsvalid(!isValid)
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      if (dataIndex === 'price_to_paid' && values[dataIndex] < 0) {
        message.error('ราคาที่จะจ่ายต้องเป็นค่าบวก');
        return;
      }
      if (dataIndex === 'price_to_paid' && values[dataIndex] > (parseFloat(record.item_totalprice)-parseFloat(record.item_paid))) {
        message.error('ราคาที่จะจ่าย เกินราคาคงเหลือ');
        return;
      }

      if (dataIndex === 'price_to_paid' && (record.item_remain === record.times_to_use) ) {
        if((parseFloat(record.item_totalprice- record.item_paid) - values[dataIndex]) !== 0)
        {
          message.error(`กรุณาจ่ายเงินให้ครบก่อนใช้บริการรอบสุดท้าย `);
          return;
        }
      }
      if (dataIndex === 'price_to_paid' && parseFloat(values[dataIndex]) === 0  && parseInt(record.times_to_use) === 0  ) {
          message.error(`ราคาที่จะจ่ายต้องเป็นค่าบวกหากจำนวนครั้งที่ใช้เป็น 0 `);
          return;
      }
      if (dataIndex === 'times_to_use' && values[dataIndex] > record.item_remain) {
          message.error(`จำนวนครั้งที่ใช้ต้องไม่มากกว่าจำนวนครั้งคงเหลือ `);
          return;
      }

      if (dataIndex === 'times_to_use' && values[dataIndex] <= 0 &&  parseFloat(record.price_to_paid) === 0) {
        message.error(`จำนวนครั้งที่ใช้ต้องเป็นค่าบวกหากราคาที่จะจ่ายเป็น 0`);
        return;
      }
      if (dataIndex === 'times_to_use' && values[dataIndex] < 0 &&  parseFloat(record.price_to_paid) !== 0) {
        message.error(`จำนวนครั้งที่ใช้ต้องเป็นค่าตั้งเเต่ 0`);
        return;
      }


      if (isNaN(values[dataIndex])) {
        message.error('กรุณาใส่เฉพาะตัวเลข');
        return;
      }

      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `กรุณากรอก ${title}`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} autoComplete='off' className='w-[5rem]' />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};




const CourseDetail = ({patient_data}) => {

    const{authState} = useContext(AuthContext)
    const {id,department_id} = useParams()
    const [isLoading, setLoading] = useState(true);
    const [loading, setLoading2] = useState(false);
    const [isModalOpen, setIsModalopen] = useState(false);
    const [isBillModalOpen, setIsBillModalOpen] = useState(false);
    const [orders, setOrders] = useState([])
    const [course, setcourse] = useState([])
    const [services, setServices] = useState([])
    const [Modifiedorder, setModifiedorder] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);
    const [option, setOptions] =useState([])
    const [cart, setCart] = useState([])
    const [totalPrice, setTotaPrice] = useState(0)
    const [cdiscount, setdiscount] = useState(0)
    const [finalprice, setfinalprice] = useState(0)
    const [employee, setemployee] = useState([]);
    const [employeeoption, setemployeeoption] =useState([]);
    const [employeeoptionWithFilter, setemployeeoptionWithFilter] = useState([]);
    const formRef = React.useRef(null);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [bildata,setBildata] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [isValid, setIsvalid] = useState(true);
    const [isPayOptionsSelected, setIsPayOptionsSelected] = useState(false)
    const [isEmployeeUnlock,setIsEmployeeUnlock] = useState(false)

    const [additionalDiscount,setAdditionalDiscount] = useState(0)
    const [isAddtionalDiscountValid, setIsAdditionalDiscountValid] = useState(true)

    const [isPay,setIsPay] = useState(false)


    const [isValidOrder, setIsValidOrder] = useState(false)

    const [bills, setBills] = useState([])

    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [confirmDeleteData,setConfirmDeleteData] = useState(null);

    const [formRef2] = Form.useForm();

    const [selectedUsedOptions , setSelectedUsedOption] = useState()

    const [isLastBillInOrder,setIsLastBillInOrder] = useState(false)
    
    // Update the state when the times or discount changes
    


    useEffect(() => {
      const fetchdata = async ()=> {
        const list_of_orders = await axios.get(process.env.REACT_APP_API_URL + `/course/patientorder/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
        const filter_list_of_oders = list_of_orders.data.filter((item) => item.item_status !== 3)
        setOrders(filter_list_of_oders);

        const services = await axios.get(process.env.REACT_APP_API_URL + `/course/service`,{ headers:{accessToken: Cookies.get('accessToken'),}})
        const filterService = services.data.filter(item=> item.department_id === authState.department_id)
        setServices(filterService);

        const employeeresponse = await axios.get(process.env.REACT_APP_API_URL + `/employee`,{ headers:{accessToken: Cookies.get('accessToken'),}});
        setemployee(employeeresponse.data);
        const newemployee = [
          ...employeeresponse.data.map(item => ({value:item.id, label: `${item.name} [${item.nickname}]`})),
          { value: -1, label: 'พาร์ทไทม์' },
          { value: 0, label: 'ไม่มี' }

        ];

        const filterNewemployee = [
          ...employeeresponse.data
            .filter(item => item.department_id === authState.department_id)
            .map(item => ({ value: item.id, label: `${item.name} [${item.nickname}]` })),
          { value: -1, label: 'พาร์ทไทม์' },
          { value: 0, label: 'ไม่มี' }
        ];
        setemployeeoption(newemployee)
        setemployeeoptionWithFilter(filterNewemployee)

        const billitemresponse =  await axios.get(process.env.REACT_APP_API_URL + `/course/billitem/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
        console.log(billitemresponse.data)
        setBills(billitemresponse.data)

        const combinedOptions = filterService.reduce((acc, item) => {
          if (item.service_type === 1) {
            acc[0].options.push({ value: item.service_id, label: item.service_name, price: item.service_price ,type:item.service_type ,disabled:false });
          } else if (item.service_type === 2) {
            acc[1].options.push({ value: item.service_id, label: item.service_name, price: item.service_price ,type:item.service_type, disabled:false });
          }
          return acc;
        }, [
          { label: 'Services', options: [] },
          { label: 'Courses', options: [] },
        ]);
        
        setOptions(combinedOptions);

        

        const newData = filter_list_of_oders.map(ordersItem => {
          let matchingItem = null;
          matchingItem = services.data.find(item => item.service_id === ordersItem.item_id);
          return {
              key: ordersItem.id,
              id: ordersItem.id,
              item_name: matchingItem.service_name,
              item_times: ordersItem.item_times,
              item_totalprice: ordersItem.item_totalprice,
              type:ordersItem.type,
              item_paid:ordersItem.item_paid,
              item_status:ordersItem.item_status,
              orders_price: ordersItem.item_times*matchingItem.service_price,
              relize_revenue: ((ordersItem.item_times-ordersItem.item_remain)*(ordersItem.item_totalprice/ordersItem.item_times)).toFixed(2),
              item_remain: ordersItem.item_remain,
              inviter_id:ordersItem.inviter_id,
              discount_value: ordersItem.discount_value,
              discount_id: ordersItem.discount_id,
              comission_type: ordersItem.comission_type
            };
        });

        setModifiedorder(newData)
        setLoading(false)
      };

      fetchdata();
  }, []);



  const handleModalCancle = () =>
  {
      const combinedOptions = services.reduce((acc, item) => {
        if (item.service_type === 1) {
          acc[0].options.push({ value: item.service_id, label: item.service_name, price: item.service_price ,type:item.service_type ,disabled:false });
        } else if (item.service_type === 2) {
          acc[1].options.push({ value: item.service_id, label: item.service_name, price: item.service_price ,type:item.service_type, disabled:false });
        }
        return acc;
      }, [
        { label: 'Services', options: [] },
        { label: 'Courses', options: [] },
      ]);
      setOptions(combinedOptions)
      setIsModalopen(false)
      setCart([])
      setSelectedOption(null)
      setSelectedUsedOption();
      setfinalprice(0);
      setdiscount(0)
      setIsPayOptionsSelected(false)
      formRef.current?.resetFields();
      form3.resetFields();
      setTotaPrice(0);
      setIsEmployeeUnlock(false);
      setAdditionalDiscount(0);
      setIsAdditionalDiscountValid(true)
      
      
  }

  const handelBillModalCancle = () =>{
    setIsBillModalOpen(false);
    setSelectedRow([]);
    setTotaPrice(0);
    setIsPayOptionsSelected(false);
    form3.resetFields();
    setIsEmployeeUnlock(false)
    setIsAdditionalDiscountValid(true);
    setAdditionalDiscount(0);
    setIsPay(false);
  }

  const handleCreateOrder = async () =>
  {
    const sqlDate = form3.getFieldValue('date').format('YYYY-MM-DD HH:mm:ss')
    setLoading(true)

    const pushdata = async () => {
      try {
          const currentDate = form3.getFieldValue('date')['$d'].toLocaleDateString('th-TH', { year: 'numeric', month: '2-digit', day: '2-digit' })

          let addDiscount = parseFloat(form3.getFieldValue('additional_discount'))

          let billitem = [];
          for (const item of cart) {
            
            const discountToApply = Math.min(addDiscount, item.price_to_paid) || 0;
            addDiscount -= discountToApply; 
            const totalPrice = formatNumber2point(item.times * item.price * (1 - parseFloat(item.discount) / 100)) - parseFloat(discountToApply||0)
            const priceToPaid = parseFloat(item.price_to_paid) - parseFloat(discountToApply || 0)
            const dd = formatNumber2point((((item.times * item.price * (parseFloat(item.discount) / 100)) + parseFloat(discountToApply||0)) * 100) / (item.times * item.price ))
            const data = {
                  item_id: item.id,
                  item_times: item.times,
                  type: item.type,
                  item_totalprice: totalPrice,
                  item_paid: priceToPaid ,
                  item_status: (parseFloat(totalPrice) - parseFloat(formatNumber2point(priceToPaid))) === parseFloat(formatNumber2point(0)) ? (parseInt(item.times) - parseInt(item.times_to_use || 0) === 0 ? 1 : 2 ): 0 ,
                  department_id: authState.department_id,
                  patent_id: id,
                  item_remain: item.times_to_use ? parseInt(item.times) - parseInt(item.times_to_use) : item.times,
                  inviter_id: item.inviter_id,
                  discount_value: item.times * item.price * (parseFloat(item.discount) / 100) + parseFloat(discountToApply|| 0) ,
                  discount_id: dd,
                  comission_type: item.inviter_percent,
                  createdAt: sqlDate,
                  updatedAt: sqlDate
              };
            const response = await axios.post(process.env.REACT_APP_API_URL + `/course`,data,{ headers:{accessToken: Cookies.get('accessToken'),}})
              
              billitem.push({
                order_id: response.data.id,
                price_to_paid: priceToPaid,
                patient_id: id,
                employee_id: item.inviter_id,
                remain_price: parseFloat(totalPrice) - parseFloat(priceToPaid) ,
                discount_value:  item.times * item.price * (parseFloat(item.discount) / 100) + parseFloat(discountToApply|| 0),
                employee_list: item.healthcare_provider_id,
                times_used:item.times_to_use,
                createdAt: sqlDate,
                updatedAt: sqlDate
              })

              if(item.use_options === 1)
              {
                for(const employees of item.healthcare_provider_id)
                {
                  
                  const comissionData = 
                    {
                      patient_order_id: response.data.id,
                      date : currentDate,
                      employee_name: employees,
                      handfee_times: item.handfee_times,
                      department_id: authState.department_id ,
                      createdAt: sqlDate,
                      updatedAt: sqlDate
                      
                    }
                    const comissionResponse = await axios.put(process.env.REACT_APP_API_URL + '/comission/addcomission-handfee', comissionData,{ headers:{accessToken: Cookies.get('accessToken'),}})
                }
              }
              const dataCom = 
              {
                date : currentDate,
                employee_name: item.inviter_id,
                onepercent: parseInt(item.inviter_percent) === 1 ? priceToPaid : 0,
                twopercent: parseInt(item.inviter_percent) === 2 ? priceToPaid : 0,
                threepercent: parseInt(item.inviter_percent) === 3 ? priceToPaid : 0,
                fourpercent: parseInt(item.inviter_percent) === 4 ? priceToPaid : 0,
                department_id: authState.department_id,
                comission_type : parseInt(item.inviter_percent),
                inviter_id: item.inviter_id,
                order_id: response.data.id,
                createdAt: sqlDate,
                updatedAt: sqlDate
              }
              const Comresponse = await axios.put(process.env.REACT_APP_API_URL + '/comission/addcomission', dataCom,{ headers:{accessToken: Cookies.get('accessToken'),}})
              
            }
            const billdata = { 
                patient_id: id, 
                department_id: authState.department_id, 
                bill_type: 1 ,
                payment_method: form3.getFieldValue('payment_method') , 
                additional_discount:form3.getFieldValue('additional_discount') || 0 ,  
                remark:form3.getFieldValue('remark') || " ",
                createdAt: sqlDate,
                updatedAt: sqlDate 
              };
            const totalPriceToPaid = billitem.reduce((total, item) => total + parseFloat(item.price_to_paid), 0);
            billdata.totoalvalue = totalPriceToPaid;
          
            const data = { bill: billdata, item: billitem };
            try {
              const billsresponse = await axios.post(process.env.REACT_APP_API_URL + `/course/billadd`, data,{ headers:{accessToken: Cookies.get('accessToken'),}});
              const billitemresponse =  await axios.get(process.env.REACT_APP_API_URL + `/course/billitem/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})

              setBills(billitemresponse.data)
            } catch (error) {
              console.error('error');
            }
      } catch (error) {
          console.error('Error creating course:', error);
      }
      };

    const fetchdata = async ()=> {
        const list_of_orders = await axios.get(process.env.REACT_APP_API_URL + `/course/patientorder/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
        const filter_list_of_oders = list_of_orders.data.filter((item) => item.item_status !== 3)
        setOrders(filter_list_of_oders);

        const newData = filter_list_of_oders.map(ordersItem => {
          let matchingItem = null;
          matchingItem = services.find(item => item.service_id === ordersItem.item_id);
          return {
              key: ordersItem.id,
              id: ordersItem.id,
              item_name: matchingItem.service_name,
              item_times: ordersItem.item_times,
              type:ordersItem.type,
              item_paid:ordersItem.item_paid,
              item_status:ordersItem.item_status,
              orders_price: ordersItem.item_times*matchingItem.service_price,
              item_totalprice: ordersItem.item_totalprice,
              item_remain: ordersItem.item_remain,
              relize_revenue: ((ordersItem.item_times-ordersItem.item_remain)*(ordersItem.item_totalprice/ordersItem.item_times)).toFixed(2),
              inviter_id:ordersItem.inviter_id,
              discount_value: ordersItem.discount_value,
              discount_id: ordersItem.discount_id,
              comission_type: ordersItem.comission_type
            };
        });
    
    setModifiedorder(newData)
    handleModalCancle();
    setLoading(false)
    };
  
    if(cart.length === 0 )
    {
      handleModalCancle();
      setLoading(false)
    }
    else
    {
      await pushdata();
      await fetchdata();
      handleModalCancle();
    }
    
  }

  const checkCart = () => 
  {
    return cart.length > 0 && isValid && isPayOptionsSelected && isAddtionalDiscountValid ;
  }

  const checkNaN=(value)=>{
    if(isNaN(value)){
      return 0
    }else{
      return value
    }
  }
  
  // Calculate the tempPrice based on the formValues
  const calculateTempPrice = () => {
    // Get the values of times, price, and discount from the form
    const timesValue = form.getFieldValue('times');
    const priceValue = form.getFieldValue('price');
    const discountValue = form.getFieldValue('discount');
  
    // Calculate the temp price based on the values
    const tempPriceValue = checkNaN(formatNumber2point(timesValue * priceValue * (1 - discountValue / 100)));
    form.setFieldsValue({ tempPrice: tempPriceValue, price_to_paid: tempPriceValue });
  };

  const calculateTempPriceForpercentage = () => {
    // Get the values of times, price, and discount from the form
    const timesValue = form.getFieldValue('times');
    const priceValue = form.getFieldValue('price');
    const discountValue = form.getFieldValue('discount');
  
    const tempPriceValue = checkNaN(formatNumber2point(timesValue * priceValue * (1 - discountValue / 100)));
    const test = checkNaN(formatNumber2point(timesValue * priceValue * (discountValue / 100)))
    

    form.setFieldsValue({ tempPrice: tempPriceValue, price_to_paid: tempPriceValue,discountV: test   });
  };

  const calculateTempPriceForValue = () => {
    // Get the values of times, price, and discount from the form
    const timesValue = form.getFieldValue('times');
    const priceValue = form.getFieldValue('price');
    const discountValue = form.getFieldValue('discount');
    const discountV = form.getFieldValue('discountV')
    
    const tempPriceValue = checkNaN(formatNumber2point((timesValue * priceValue) - discountV));
    const test =checkNaN((discountV * 100)/ (timesValue * priceValue))

    form.setFieldsValue({ tempPrice: tempPriceValue, price_to_paid: tempPriceValue,discount: test   });
  };


  const checkSelectedRow = () => {
    let priceValid = false;
    let employeeValid = false;
    
    for (const item of selectedRow) {

      if (parseInt(item.item_remain) === parseInt(item.times_to_use)) {
        if ((parseFloat(item.item_totalprice - item.item_paid) - parseFloat(item.price_to_paid)) !== 0 ) {  
          priceValid = true;
        }
        else
        {
          priceValid = false
        }
      } 
      if (!item.employee_id) {
        employeeValid = true;
      } else {
        if(item.employee_id.length > 0)
        {
          employeeValid = employeeValid || false;
        }
        else
        {
          employeeValid = true
        }
      }
    }


    return employeeValid || priceValid || !isPayOptionsSelected || !isAddtionalDiscountValid; 
  };

    
  const billCreate = async () =>
  {
    setLoading(true)
    const currentDate = form3.getFieldValue('date')['$d'].toLocaleDateString('th-TH', { year: 'numeric', month: '2-digit', day: '2-digit' })
    const sqlDate = form3.getFieldValue('date').format('YYYY-MM-DD HH:mm:ss')

    const updatadata = async () => {
      
      try {
          for (const item of selectedRow) {
              
              const itemPaid = parseFloat(item.item_paid) + parseFloat(item.price_to_paid);
              const isPaidInFull = parseFloat(itemPaid) === parseFloat(item.item_totalprice);
              let itemStatus = isPaidInFull ? 2 : 0;
              const itemRemain = item.item_remain - item.times_to_use;
              const itemUsedinFUll = parseInt(itemRemain) === 0;
              itemStatus = itemUsedinFUll ? 1 : itemStatus;
              const data = {
                id: item.id,
                item_paid: itemPaid,
                item_status: itemStatus,
                item_remain: itemRemain
            };

            const dataCom = 
              {
                date : currentDate,
                employee_name: item.inviter_id,
                onepercent: parseInt(item.comission_type) === 1 ? item.price_to_paid : 0,
                twopercent: parseInt(item.comission_type) === 2 ? item.price_to_paid : 0,
                threepercent: parseInt(item.comission_type) === 3 ? item.price_to_paid : 0,
                fourpercent: parseInt(item.comission_type) === 4 ? item.price_to_paid : 0,
                department_id: authState.department_id,
                comission_type : parseInt(item.comission_type),
                inviter_id: item.inviter_id,
                order_id: item.id,
                createdAt: sqlDate,
                updatedAt: sqlDate
              }
              const Comresponse = await axios.put(process.env.REACT_APP_API_URL + '/comission/addcomission', dataCom,{ headers:{accessToken: Cookies.get('accessToken'),}})


            for(const employees of item.employee_id)
            {
              const comissionData = 
              {
                date : currentDate,
                employee_name: employees,
                handfee_times: item?.handfee_times || 0,
                department_id: authState.department_id ,
                patient_order_id: item.id,
                createdAt: sqlDate,
                updatedAt: sqlDate
              }
              const comissionResponse = await axios.put(process.env.REACT_APP_API_URL + '/comission/addcomission-handfee', comissionData,{ headers:{accessToken: Cookies.get('accessToken'),}})

            }
            const response = await axios.put(process.env.REACT_APP_API_URL + `/course/update`,data,{ headers:{accessToken: Cookies.get('accessToken'),}})
          }
      } catch (error) {
          console.error('Error update course:', error);
      }
    
      };

      const pushdata = async() => {
        const billdata = { 
          patient_id: id, 
          department_id: authState.department_id,  
          bill_type: 2 , 
          payment_method:form3.getFieldValue('payment_method') ,
          additional_discount: 0, 
          remark:form3.getFieldValue('remark') || " ",
          createdAt: sqlDate,
          updatedAt: sqlDate
        };
        const billitem = selectedRow.map(item => ({
          order_id: item.id,
          price_to_paid: item.price_to_paid,
          patient_id: id,
          employee_id: 0,
          remain_price: parseInt(item.remain_price) - parseInt(item.price_to_paid),
          discount_value: item.discount_value,
          employee_list: item.employee_id,
          times_used:item.times_to_use,
          createdAt: sqlDate,
          updatedAt: sqlDate
        }));
      
        const totalPriceToPaid = billitem.reduce((total, item) => total + parseFloat(item.price_to_paid), 0);
        billdata.totoalvalue = totalPriceToPaid;
      
        const data = { bill: billdata, item: billitem };
        try {
          const billsresponse = await axios.post(process.env.REACT_APP_API_URL + `/course/billadd`, data,{ headers:{accessToken: Cookies.get('accessToken'),}});
          const billitemresponse =  await axios.get(process.env.REACT_APP_API_URL + `/course/billitem/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
          setBills(billitemresponse.data)
        } catch (error) {
          console.error('error');
        }
      }


    const fetchdata = async ()=> {
      const list_of_orders = await axios.get(process.env.REACT_APP_API_URL + `/course/patientorder/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
      const filter_list_of_oders = list_of_orders.data.filter((item) => item.item_status !== 3)
      setOrders(filter_list_of_oders);

      const newData = filter_list_of_oders.map(ordersItem => {
        let matchingItem = null;
        matchingItem = services.find(item => item.service_id === ordersItem.item_id);
        return {
            key: ordersItem.id,
            id: ordersItem.id,
            item_name: matchingItem.service_name,
            item_times: ordersItem.item_times,
            type:ordersItem.type,
            item_paid:ordersItem.item_paid,
            item_status:ordersItem.item_status,
            orders_price: ordersItem.item_times*matchingItem.service_price,
            item_remain: ordersItem.item_remain,
            item_totalprice: ordersItem.item_totalprice,
            relize_revenue: ((ordersItem.item_times-ordersItem.item_remain)*(ordersItem.item_totalprice/ordersItem.item_times)).toFixed(2),
            inviter_id:ordersItem.inviter_id,
            discount_value: ordersItem.discount_value,
            discount_id: ordersItem.discount_id,
            comission_type: ordersItem.comission_type
          };
      });
    
    setModifiedorder(newData)
    
  };

    await updatadata();
    await pushdata();
    await fetchdata();
    handelBillModalCancle()
    setIsBillModalOpen(false)
    setTotaPrice(0)
    setSelectedRow([])
    setLoading(false)

  };
    
  
  const handleDelete = (key) => {
    const newData = cart.filter((item) => item.id !== key);
    setCart(newData);
    setTotaPrice(newData.reduce((acc, item) => acc + item.times * item.price, 0))
    setdiscount(newData.reduce((acc, item) => acc + (item.times * item.price)*item.discount/100, 0))
    setfinalprice(newData.reduce((acc, item) => acc + (item.times * item.price)-((item.times * item.price)*item.discount/100), 0))
    const updatedOptions = option.map((group) => {
      const updatedOptionsInGroup = group.options.map((item) => {
        if (item.value === key) {
          return { ...item, disabled: false };
        }
        return item;
      });
      return { ...group, options: updatedOptionsInGroup };
    });
    setOptions(updatedOptions);
    
  };

  const handleFinishedCourse = async (record) =>
  {
        setLoading(true)
        const updatadata = async () => {
          try {
                  const data = {
                    id: record.id,
                    item_status: 3,
                };
                  const response = await axios.put(process.env.REACT_APP_API_URL + `/course/update`,data,{ headers:{accessToken: Cookies.get('accessToken'),}})
          } catch (error) {
              console.error('Error creating departments:', error);
          }
        
          };
        const fetchdata = async ()=> {
          const list_of_orders = await axios.get(process.env.REACT_APP_API_URL + `/course/patientorder/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
          const filter_list_of_oders = list_of_orders.data.filter((item) => item.item_status !== 3)
          setOrders(filter_list_of_oders);

          const newData = filter_list_of_oders.map(ordersItem => {
            let matchingItem = null;
            matchingItem = services.find(item => item.service_id === ordersItem.item_id);
            return {
                key: ordersItem.id,
                id: ordersItem.id,
                item_name: matchingItem.service_name,
                item_times: ordersItem.item_times,
                type:ordersItem.type,
                item_paid:ordersItem.item_paid,
                item_status:ordersItem.item_status,
                orders_price: ordersItem.item_times*matchingItem.service_price,
                item_remain: ordersItem.item_remain,
                item_totalprice: ordersItem.item_totalprice,
                relize_revenue: ((ordersItem.item_times-ordersItem.item_remain)*(ordersItem.item_totalprice/ordersItem.item_times)).toFixed(2),
                inviter_id:ordersItem.inviter_id,
                discount_value: ordersItem.discount_value,
                discount_id: ordersItem.discount_id,
                comission_type: ordersItem.comission_type
              };
          });
        setModifiedorder(newData)  
      };
      
        await updatadata();
        await fetchdata();
        setLoading(false)
      }


  const handleDeleteCourse = async (record,index) =>
  {
    setConfirmDeleteData(record)
    setIsLastBillInOrder(index===0);
    setSelectedRow([])
    setSelectedRowKeys([])
    setIsConfirmDeleteModalOpen(true)
  }

  const handleDeleteCancel= () =>
  {
    formRef2.resetFields();
    setIsLastBillInOrder(false);
    setIsConfirmDeleteModalOpen(false)
    
  }
  
  const handleDeleteConfirm = async() =>
  {
    setLoading(true)
    const fetchdata = async ()=> {
      const billitemresponse =  await axios.get(process.env.REACT_APP_API_URL + `/course/billitem/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
      setBills(billitemresponse.data)
      const list_of_orders = await axios.get(process.env.REACT_APP_API_URL + `/course/patientorder/byId/${id}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
      const filter_list_of_oders = list_of_orders.data.filter((item) => item.item_status !== 3)
      setOrders(filter_list_of_oders);

      const newData = filter_list_of_oders.map(ordersItem => {
        let matchingItem = null;
        matchingItem = services.find(item => item.service_id === ordersItem.item_id);
        return {
            key: ordersItem.id,
            id: ordersItem.id,
            item_name: matchingItem?.service_name,
            item_times: ordersItem.item_times,
            type:ordersItem.type,
            item_paid:ordersItem.item_paid,
            item_status:ordersItem.item_status,
            orders_price: ordersItem.item_times*matchingItem.service_price,
            item_totalprice: ordersItem.item_totalprice,
            item_remain: ordersItem.item_remain,
            relize_revenue: ((ordersItem.item_times-ordersItem.item_remain)*(ordersItem.item_totalprice/ordersItem.item_times)).toFixed(2),
            inviter_id:ordersItem.inviter_id,
            discount_value: ordersItem.discount_value,
            discount_id: ordersItem.discount_id,
            comission_type: ordersItem.comission_type
          };
      
      });
      setModifiedorder(newData)}
    const password = formRef2.getFieldsValue();
    const response = await axios.post(process.env.REACT_APP_API_URL + '/auth/validatePassword',password, { headers:{accessToken: Cookies.get('accessToken')}})
    if(response.data.error)
    {
      alert("รหัสผ่านไม่ถูกต้อง")
      setLoading(false)
    }
    else 
    {
      const deleteComissionResponse = await axios.put(process.env.REACT_APP_API_URL+ `/comission/delete-comission/${parseInt(confirmDeleteData?.order_id)}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
      const deleteResponse = await axios.delete(process.env.REACT_APP_API_URL + `/course/bill-delete/${confirmDeleteData?.id}/${confirmDeleteData.employee_list.length}`,{ headers:{accessToken: Cookies.get('accessToken'),}})
      await fetchdata()
      setLoading(false)
      handleDeleteCancel();
    }
  }



    
    
    


    const column = [
          {
            title: 'Order ID',
            dataIndex: 'id',
            key:'id',
            width: '5%',
            align:"center",
            
        },
        {
            title: 'ชื่อ',
            dataIndex: 'item_name',
            key:'item_name',
            align:"left"
        }, 
        {
            title: 'จำนวนครั้ง',
            dataIndex: 'item_times',
            key:'item_times',
            width: '3.5%',
            align:"center"
        },
        {
          title: 'คงเหลือ',
          dataIndex: 'item_remain',
          key:'item_remain',
          width: '3.5%',
          align:"center"
      },
        
        {
            title: 'ราคา',
            dataIndex: 'item_totalprice',
            key:'item_totalprice',
            width: '5%',
            align:"center",
            render:(item_totalprice) =>{
              return formatNumberWithCommas(item_totalprice)
            } 
        },
        {
          title: 'ส่วนลด',
          dataIndex: 'discount_value',
          key:'discount_value',
          width: '5%',
          align:"center",
          render:(discount_value) =>{
            return formatNumberWithCommas(discount_value)
          } 
        },
        {
          title: 'ราคาที่จ่ายไปเเล้ว',
          dataIndex: 'item_paid',
          key:'item_paid',
          width: '5%',
          align:"center",
          render:(item_paid) =>{
            return formatNumberWithCommas(item_paid)
          } 
        },
        {
          title: 'รายรับจริง',
          dataIndex: 'relize_revenue',
          key:'relize_revenue',
          align:"center",
          width: '5%',
          render:(relize_revenue) =>{
            return formatNumberWithCommas(relize_revenue)
          } 
        },
        {
          title: 'รหัสผู้แนะนำ',
          dataIndex: 'inviter_id',
          key:'inviter_id',
          align:"center",
          width: '7%',
          render: (inviter_id) => {
            if (inviter_id === -1) {
              return <span>พาร์ทไทม์</span>;
            } else if (inviter_id === 0) {
              return <span>ไม่มี</span>;
            }
            return inviter_id; 
          },
        },
        {
          title: 'ประเภท',
          dataIndex: 'type',
          key:'type',
          align:"center",
          width: '7%',
          render: (type) => {
            if (type === 1) {
              return <span>บริการ</span>;
            } else if (type === 2) {
              return <span>คอร์สต์</span>;
            }
            return null; 
          },
        },
        {
          title: 'สถานะ',
          dataIndex: 'item_status',
          key:'item_status',
          align:"center",
          width: '15%',
          render : (item_status) =>{
            if(item_status === 1){
              return <Badge status="success" text="รายการเสร็จสิ้น" />
            }
            else if (item_status === 0)
            {
              return <Badge status="processing" text="อยู่ระหว่างดำเนินการ" />
            }
            else if (item_status === 2)
            {
              return <Badge status="warning" text="ชำระเสร็จสิ้น" />
            }
          }
        },
        {
          title: 'จัดการ',
          dataIndex: 'operation',
          align:'center',
          width: '12%',
          render: (_, record) =>
          {
            const finished = record.item_status === 1;
            return (
            <div className='flex gap-x-1 justify-center'>
              <Button className={`border-[1px] border-black  rounded-md px-1  ${finished ? 'bg-[#6674c4] hover:translate-y-[-3px] duration-100 text-[#ffffff]' : 'bg-grey text-[#131212] '}`}  disabled={!finished || authState.department_id === 0} onClick={()=>{handleFinishedCourse(record)}}>เสร็จสิ้น</Button>
            </div>
          )}
          ,
        },
    ]

    const ordercolumn = [
      {
        title: 'ID',
        dataIndex: 'id',
        key:'id'
      },
      {
          title: 'ชื่อ',
          dataIndex: 'name',
          key:'name'
      },
      {
          title: 'จำนวนครั้ง',
          dataIndex: 'times',
          key:'times',
          editable: true
      },
      {
          title: 'ราคาต่อหน่วย',
          dataIndex: 'price',
          key:'price',
          render:(price) =>{
            return formatNumberWithCommas(price)
          } 
      },
      {
        title: 'ราคารวม',
        dataIndex: 'totalPrice',
        key:'totalPrice',
        render:(totalPrice) =>{
          return formatNumberWithCommas(totalPrice)
        } 
      },
      {
        title: 'ส่วนลด',
        dataIndex: 'discount',
        key:'discount',
        render: (_, record) =>{
          return(
            <span>{formatNumberWithCommas((record.discount/100 * record.totalPrice).toFixed(2)) }</span>
          )
        }
      },
      
      
      {
        title: 'รหัสผู้แนะนำ',
        dataIndex: 'inviter_id',
        key:'inviter_id'
      },
      {
          title: 'จ่ายราคา',
          dataIndex: 'price_to_paid',
          key:'price_to_paid',
          render:(price_to_paid) =>{
            return formatNumberWithCommas(price_to_paid)
          } 
      },
      
      {
      title: 'จำนวนที่ใช้',
      dataIndex: 'times_to_use',
      key:'times_to_use',
      },
      {
        title: 'จำนวนที่ใช้',
        dataIndex: 'times_to_use',
        key:'times_to_use',
        },
        
      {
        title: 'ตัวเลือกการใช้บริการ',
        dataIndex: 'use_options',
        key:'use_options',
        render: (use_options) => {
          if (use_options === 0) {
            return <span>ไม่ใช้</span>;
          } else if (use_options === 1) {
            return <span>ใช้</span>;
          }
          return null; 
        },
    },
      {
        title: 'ประเภท',
        dataIndex: 'type',
        key:'type',
        render: (type) => {
          if (type === 1) {
            return <span>บริการ</span>;
          } else if (type === 2) {
            return <span>คอร์สต์</span>;
          }
          return null; 
        },
      },
      {
        title: 'จัดการ',
        dataIndex: 'operation',
        render: (_, record) =>
          cart.length >= 1 ? (
              <Button  onClick={() => handleDelete(record.id)}>Delete</Button>
          ) : null,
      },
    ]

    const billingcolumn = [
      {
        title: 'Order ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'ชื่อ',
        dataIndex: 'item_name',
        key: 'item_name',
      },
      {
        title: 'จำนวนครั้ง',
        dataIndex: 'item_times',
        key: 'item_times',
      },
      {
        title: 'คงเหลือ',
        dataIndex: 'item_remain',
        key: 'item_remain',
      },
      {
        title: 'ราคารวม',
        dataIndex: 'item_totalprice',
        key: 'item_totalprice',
        render:(item_totalprice) =>{
          return formatNumberWithCommas(item_totalprice)
        } 
      },
      {
        title: 'ราคาคงเหลือ',
        dataIndex: 'remain_price',
        key: 'remain_price',
        render: (_, record) => {
          return formatNumberWithCommas(record.item_totalprice - record.item_paid);
        },
      },
      {
        title: 'ราคาที่จะจ่าย',
        dataIndex: 'price_to_paid',
        key: 'price_to_paid',
        editable: true,
        render:(price_to_paid) =>{
          return formatNumberWithCommas(price_to_paid)
        } 
      },
      {
        title: 'จำนวนครั้งที่ใช้',
        dataIndex: 'times_to_use',
        key: 'times_to_use',
        editable: true,
      },
      {
        title: 'ประเภท',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'จำนวนค่ามือ',
        dataIndex: 'handfee_times',
        key: 'handfee_times',
        render:(_,record) =>(
          <InputNumber onChange={
          (value) => {
            const newData = selectedRow.map((item) =>
              item.id === record.id ? { ...item, handfee_times: value } : item
            );
            setSelectedRow(newData);
          }}
          max={100}
          min={0}
          defaultValue={0}
          >
          </InputNumber>
        )
      },
      {
        title: 'พนักงาน',
        dataIndex: 'employee_id',
        key: 'employee_id',
        render: (_, record) => (
          <Select 
            mode='multiple'
            showSearch
            placeholder="กรุณาเลือกผู้รักษา"
            value={record.employee_id}
            onChange={(value) => {
              if (value?.length > 3) {
                value.pop();
                return
              }
              const newData = selectedRow.map((item) =>
                item.id === record.id ? { ...item, employee_id: value } : item
              );
              setSelectedRow(newData);
            }}
            style={{ width: '20rem' }}
            filterOption={(input, option) =>
              option.value.toString().toLowerCase().includes(input.toLowerCase()) ||
              option.children.toLowerCase().includes(input.toLowerCase())
            }
>
  {(isEmployeeUnlock?  employeeoption:employeeoptionWithFilter).map((employee) => (
    <Select.Option key={employee.value} value={employee.value}>
      {employee.label}
    </Select.Option>
  ))}
</Select>
        ),
      },
    ];

  const handleSave = (row) => {
    const totalPrice = row.price * row.times;
    const newData = cart.map(item =>
      item.id === row.id ? { ...item, times: row.times ,totalPrice:totalPrice } : item
    );
    
    setCart(newData);
    setTotaPrice(newData.reduce((acc, item) => acc + item.times * item.price, 0))
    
    setfinalprice(newData.reduce((acc, item) => acc + (item.times * item.price)-((item.times * item.price)*item.discount/100), 0))
  };



  const handleSaveBilling = (row) => {
    const newData = selectedRow.map(item =>
      item.id === row.id ? { ...item, price_to_paid: row.price_to_paid ,  times_to_use:row.times_to_use } : item
    );
    const ttp =  newData.reduce((acc, item) => acc + parseFloat(item.price_to_paid), 0)
    setTotaPrice(ttp)
    setIsPay(ttp>0?true: false)
    setSelectedRow(newData);

  };

        


  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  

  const columns = ordercolumn.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        setIsvalid, 
        isValid,
      }),
    };
  });

      
  const columnsBilling = billingcolumn.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSaveBilling, 
        setIsvalid, 
        isValid,
      }),
    };
  });




    


    

  const onFinish = (values) => {
    const totalPrice = values.price * values.times;

    const test = { ...selectedOption, disabled: true };
    const newData = [...option];
    const index = newData.findIndex((item) => test.value === item.value);
    if (index !== -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...test,
      });
    } else {
      newData.forEach((group) => {
        group.options = group.options.map((item) => {
          if (item.value === test.value) {
            return { ...item, disabled: true };
          }
          return item;
        });
      });
    }

    const valuesWithTotalPrice = { ...values, totalPrice: totalPrice , name: selectedOption.label, type: selectedOption.type , item_remain:values.times , times_to_use: values.times_to_use  ? values.times_to_use : 0 , healthcare_provider_id: values.healthcare_provider_id || [] , handfee_times: values.handfee_times || 0 };
    const newItems = [...cart, valuesWithTotalPrice ];
    formRef.current?.resetFields();
    const billitem = [...bildata, {id:values.id, name:selectedOption.label,amount: values.times, price:values.price, discount:values.discount, totalPrice: totalPrice }]
    
    setBildata(billitem)
    setCart(newItems);
    setSelectedOption(null)
    setTotaPrice(newItems.reduce((acc, item) => acc + item.times * item.price, 0))
    setdiscount(newItems.reduce((acc, item) => acc + (item.times * item.price)*item.discount/100, 0))
    setfinalprice(newItems.reduce((acc, item) => acc + (item.times * item.price)-((item.times * item.price)*item.discount/100), 0))
    setOptions(newData)
    setSelectedUsedOption();

  };

  const select = (selectedValue, selectedOption) => {
    setSelectedOption(selectedOption)
    form.setFieldsValue({times:1 ,price: selectedOption.price, type: selectedOption.type, name:selectedOption.label,discount:0,discountV:0 , price_to_paid: selectedOption.price});
  }

  const onSelectChange = (newSelectedRowKeys,newSelectedRow) => {
  setSelectedRowKeys(newSelectedRowKeys);
  setSelectedRow(newSelectedRow.map((item)=>({...item, price_to_paid:0,times_to_use:1, remain_price: parseFloat(item.item_totalprice) - parseFloat(item.item_paid), discount_value: item.discount_value})));
  };

  const rowSelection2 = {
    selectedRowKeys,
    selectedRow,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.item_status === 1,
    }),

  };



  const hasSelected = selectedRowKeys.length > 0;
    

  const start = () => {
    
    setLoading2(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading2(false);
      setIsBillModalOpen(true);
    }, 1000);
  };

    

  const tableCSS = css({
    backgroundColor: 'white',
    '& table': {
      borderCollapse: 'collapse'
    },
    '& thead > tr': {
      borderWidth: '1px',
      borderColor: 'black',
      borderStyle: 'solid',
      backgroundColor: "blue"
    },
    '.ant-table-thead .ant-table-cell':
    {
      backgroundColor: '#00BA9E ',
      color: 'white ',
      borderRadius: 'unset !important '
    },
    '.ant-table-cell:hover':
    {
      cursor:'pointer'
    },
    '.ant-table-tbody .ant-table-cell':
    {
      textAlign: 'center'

    }
      
  });

  const billtableCSS = css({
    backgroundColor: 'white',
    '& table': {
      borderCollapse: 'collapse'
    },
    '& thead > tr': {
      borderWidth: '1px',
      borderColor: 'black',
      borderStyle: 'solid',
      backgroundColor: "blue"
    },
    '.ant-table-thead .ant-table-cell':
    {
      backgroundColor: 'grey',
      color: 'white ',
      borderRadius: 'unset'
    },
    '.ant-table-cell:hover':
      {
        cursor:'pointer'
      }
    
  });


  const expandedRowRender = (record) => {
    const filteredBillItems = bills.filter((item) => parseInt(item.order_id) === record.id);
    const selectedBill = Modifiedorder.find((item) => record.id === item.id)
    const modifiedData = filteredBillItems.map(item => {
      return{
        ...item,
        relize_revenue : item.times_used*(selectedBill.item_totalprice/selectedBill.item_times).toFixed(2)
      }}
    )
    const ordercolumn = [
        {
          title: 'ลำดับ',
          dataIndex: 'index',
          key:'index',
          width: '5%',
          render: (text, record, index) => index + 1,
        },
        {
            title: 'วันที่',
            dataIndex: 'createdAt',
            key:'createdAt',
            width: '30%'
        },
        {
          title: 'จำนวนที่ใช้',
          dataIndex: 'times_used',
          key:'times_used',
          width: '10%'
        },
        {
            title: 'ราคาที่จ่าย',
            dataIndex: 'price_to_paid',
            key:'price_to_paid',
            render:(price_to_paid) =>{
              return formatNumberWithCommas(price_to_paid)
            } 
        },
        {
          title: 'รายรับจริง',
          dataIndex: 'relize_revenue',
          key:'relize_revenue',
          width: '10%',
          render:(relize_revenue) =>{
            return formatNumberWithCommas(relize_revenue)
          } 
        },
        {
          title: 'ราคาคงเหลือ',
          dataIndex: 'remain_price',
          key:'remain_price',
          render:(remain_price) =>{
            return formatNumberWithCommas(remain_price)
          } 
        },
        {
          title: 'รหัสผู้ทำการรักษา',
          dataIndex: 'employee_list',
          key: 'employee_list',
          width: '10%',
          render(_, record, index) {
            if (record.employee_list?.length === 0 ) {
              return <span>ไม่มี</span>;
            }
            return record.employee_list?.map((employee, employeeIndex) => (
              <span key={employeeIndex}>
                {employee === 0  ? "-" : employee === -1 ? "-" : employee}
                {employeeIndex !== record.employee_list.length - 1 && ','}
              </span>
            ));
          }
        },
        {
          title: 'จำนวนค่ามือ',
          dataIndex: 'handfee',
          key: 'handfee',
          width: '10%',
          render(_, record, index) {
            if (record.employee_list?.length === 0 ) {
              return <span>ไม่มี</span>;
            }
            return record.employee_list?.map((employee, employeeIndex) => (
              <span key={employeeIndex}>
                {employee === 0  ? "-" : employee === -1 ? "-" : record.handfee}
                {employeeIndex !== record.employee_list.length - 1 && ','}
              </span>
            ));
          }
        },
        {
          title: 'จัดการ',
          dataIndex: 'operation',
          key:'operation',
          render(_,record , index)
          {
            const lastIndex = index === (filteredBillItems.length - 1)
            return(
              <Button onClick={()=>{handleDeleteCourse(record,index)}} disabled={!lastIndex || authState.department_id === 0}>ลบ</Button>
            )
          }
        },
      ]

    return <Table columns={ordercolumn} pagination={false} dataSource={modifiedData} className={billtableCSS} />;
  };


  if (isLoading)
  {
    return <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}open>
    <CircularProgress color="inherit" />
    </Backdrop>
   ;
  }

  const onCheckChange =(e)=>
  {

    setIsEmployeeUnlock(e.target.checked)
    setSelectedUsedOption();
    form.resetFields();
  }

  const formatNumber2point = (number) => {
    return parseFloat(number).toFixed(2);
  }


  return (
    <>    
          <div className='shadow-md md:p-[24px]  md:bg-[#E77B7C] md:shadow-2xl md:mx-[10rem] mx-[0.5rem] rounded-xl ' >
          <div className={`  md:px-[24px] px-[0.5rem] pt-[24px] bg-white md:shadow-2xl rounded-xl ${(Modifiedorder.length === 0 ) ? 'pb-[24px]' : null}`}>
              <Table dataSource={Modifiedorder.reverse()} columns={column} bordered className={tableCSS} rowSelection={{...rowSelection2}} pagination={{ showPagination: 'always', pageSize: 6}} size="small" rowKey={"key"} 
              expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: ['0'],
              }}
              scroll={{x:'max-content'}}
              />
            </div>
          </div>
          <div className=' flex gap-x-5 justify-center mt-[1rem]'>
            <Button onClick={()=> {setIsModalopen(true)}} className='mb-10 bg-white' disabled={authState.department_id === 0}>+ เพิ่มรายการ</Button>

            <Button  onClick={start} disabled={!hasSelected || authState.department_id === 0 } loading={loading} className='mb-10 bg-white'>
              ตัดบิล
            </Button>
          </div>


        {/*Create Order*/}
        <Modal width={1000} title="สร้างรายการ" open={isModalOpen} onCancel={handleModalCancle} footer={[<Button key="ok" onClick={handleCreateOrder} disabled={!checkCart()} >ตกลง</Button>,<Button key="close" onClick={handleModalCancle}>ปิด</Button>]}>
        <Checkbox checked={isEmployeeUnlock} onChange={onCheckChange}>ปลดล็อคพนักงาน[สำหรับพนักงานต่างสาขา]</Checkbox>
        <Form name="basic"
            ref={formRef}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout='vertical'
            className=' flex flex-col'
          >
            <div className='flex'>
              <div className='flex-1'>
              <Form.Item name="id" label="รายการ" rules={[{ required: true ,message: 'กรุณาเลือกรายการบริการ'}]} style={{ marginBottom: "10px" }}>
              <Select
                showSearch
                placeholder="Select"
                options={option}
                allowClear
                filterOption={(inputValue, option) =>
                  (option.label && option.label.toLowerCase().includes(inputValue.toLowerCase())) ||
                  (option.value && option.value.toString().toLowerCase().includes(inputValue.toLowerCase()))
                }
                onChange={(selectedValue, selectedOption) => {
                  select(selectedValue, selectedOption);
                  calculateTempPrice();
                }}
              />
              </Form.Item>
              </div>

              <Form.Item style={{ marginBottom: "10px" }} name="times" label="จำนวน" rules={[ 
                  {
                    type: 'number',
                    min: 1,
                    max: 100,
                    message: 'จำนวนต้องอยู่ระหว่าง 0 ถึง 100 เท่านั้น',
                  },
                ]} >
                <InputNumber defaultValue={0} onChange={calculateTempPrice}/>
              </Form.Item>
            </div>

            <div className='flex'>
              <div className='flex-1'>
            <Form.Item name="price" label="ราคา" style={{ marginBottom: "10px" }} >
              <Input placeholder="ราคา" readOnly={true} defaultValue={0}/>
            </Form.Item>
            </div>
            
            <Form.Item name="tempPrice" label="ราคารวมส่วนลด" style={{ marginBottom: "10px" }} >
              <Input placeholder="ราคา" readOnly={true} defaultValue={0}/>
            </Form.Item>
            </div>

            <Form.Item name="inviter_id" label="ผู้แนะนำ" rules={[{ required: true ,message: 'กรุณาเลือกชื่อผู้แนะนำ'}]} style={{ marginBottom: "0px" }}>
              <Select options={isEmployeeUnlock?  employeeoption:employeeoptionWithFilter} placeholder="กรุณาเลือกผู้แนะนำ"showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase()) ||
                  option.value.toString().toLowerCase().includes(input.toLowerCase())
                }/>
            </Form.Item>

            <Form.Item name="inviter_percent" 
              label="เปอเซนต์แนะนำ" 
              rules={[{ required: true ,message: 'กรุณาเลือกชื่อผู้แนะนำ'}]} 
              style={{ marginBottom: "0px" }}>
              <Radio.Group>
                <Radio value="1" > 1% </Radio>
                <Radio value="2" > 2% </Radio>
                <Radio value="3"> 3% </Radio>
                <Radio value="4"> 4% </Radio>
                <Radio value="0" > ไม่มี</Radio>
              </Radio.Group>
            </Form.Item>

            

            <div className='flex gap-5 '>
              <div className='flex-1 flex '>
              <Form.Item  style={{ marginBottom: "10px" }}
                  name="discount" 
                  label="ส่วนลด %"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value === undefined || value === null) {
                          return Promise.reject(new Error('กรุณาใส่ส่วนลดในช่วง 0 ถึง 100 '));
                        }
                        const numberValue = Number(value);
                        if (isNaN(numberValue)) {
                          return Promise.reject(new Error('ส่วนลดต้องเป็นตัวเลขเท่านั้น'));
                        }
                        if (numberValue < 0 || numberValue > 100) {
                          return Promise.reject(new Error('ส่วนลดต้องอยู่ในช่วง 0 ถึง 100'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder="โค้ดส่วนลด" onChange={calculateTempPriceForpercentage} />
                </Form.Item>
                <Form.Item  style={{ marginBottom: "10px" }}
                  name="discountV" 
                  label="ส่วนลด"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator: (_, value) => {
                        const tempPrice = formatNumber2point((parseFloat(getFieldValue('price')) * parseFloat(getFieldValue('times'))))
                        if (value === undefined || value === null) {
                          return Promise.reject(new Error('กรุณาใส่ส่วนลดที่เป็นตัวเลข'));
                        }
                        const numberValue = Number(value);
                        if (isNaN(numberValue)) {
                          return Promise.reject(new Error('ส่วนลดต้องเป็นตัวเลขเท่านั้น'));
                        }
                        if ((numberValue < 0) || (numberValue > tempPrice )) {
                          return Promise.reject(new Error(`ส่วนลดต้องมากกว่า 0 เเละน้อยกว่า ${tempPrice}` ));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="ส่วนลด" onChange={calculateTempPriceForValue} />
                </Form.Item>
              </div>
              {/* <Form.Item name="ตรวจสอบโค้ด" style={{ marginBottom: "10px" }} label=" ">
                <Button>ตรวจสอบโค้ด</Button>
              </Form.Item> */}
            </div>

            <Form.Item  style={{ marginBottom: "10px" }}
                label="ราคาที่จะจ่าย"
                name="price_to_paid"
                
                rules={[
                  {
                    required: true,
                    message: 'กรุณาใส่ราคาที่จะจ่าย ',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value !== null && value !== undefined) {
                        const totalPrice = formatNumber2point((parseFloat(getFieldValue('price')) * parseFloat(getFieldValue('times')) * (1-parseFloat(getFieldValue('discount')/100))))
                        if (totalPrice < value) {
                          return Promise.reject(new Error(`ราคาที่จะจ่ายมากกว่าราคาสินค้า [ราคาสินค้ารวมส่วนลด ${totalPrice}]`))
                        }else if (value< 0) {
                          return Promise.reject(new Error(`ราคาที่จะจ่ายห้ามน้อยกว่า 0 `))
                        }
                        else if(getFieldValue('times_to_use'))
                        {
                          if(parseInt(getFieldValue('times')) - parseInt(getFieldValue('times_to_use')) <= 0 && (parseFloat(totalPrice) !== parseFloat(value) ))
                          {
                            return Promise.reject(new Error(`กรุณาชำระเงินให้ครบก่อนใช้บริการครบทุกครั้ง [ราคาสินค้ารวมส่วนลด ${totalPrice}]`))
                          }
                          else
                          {
                            return Promise.resolve();
                          }
                        }
                        else {
                          return Promise.resolve();
                        }
                      } else {
                        return Promise.resolve(); 
                      }
                    }
                  }),
                ]}
              >
                <InputNumber className='w-full' placeholder='กรุณาใส่ราคาที่จะจ่าย' />
              </Form.Item>

              <Form.Item  style={{ marginBottom: "10px" }}
                      label="ตัวเลือกการใช้บริการ"
                      name="use_options"
                      rules={[
                        {
                          required: true,
                          message: 'กรุณาเลือกตัวเลือกการใช้บริการ',
                        },
                      ]}
                    >
                      <Radio.Group onChange={(e) => setSelectedUsedOption(e.target.value)} >
                        <Radio value={0} > ยังไม่ใช้ </Radio>
                        <Radio value={1}> ใช้เลย </Radio>
                        {selectedUsedOptions ===  1 && (
                          <div className='flex gap-10'>
                        <Form.Item name="healthcare_provider_id" label="ผุ้ทำการรักษา [เลือกสูงสุด 3 คน]" rules={[{ required: true ,message: 'กรุณาเลือกชื่อผู้แนะนำ'}]} style={{ marginBottom: "0px" , marginTop:"10px" }}>
                          <Select options={isEmployeeUnlock?  employeeoption:employeeoptionWithFilter} placeholder="กรุณาเลือกรักษา"showSearch mode="multiple" maxTagCount={3}
                            filterOption={(input, option) =>
                              option.label.toLowerCase().includes(input.toLowerCase()) ||
                              option.value.toString().toLowerCase().includes(input.toLowerCase())
                            }
                            onChange={value => {
                              if (value?.length > 3) {
                                value.pop();
                              }
                              
                            }}
                            />
                        </Form.Item>
                            <Form.Item name="times_to_use" label="จำนวนที่ใช้" 
                            rules={[{ 
                              required: true ,message: 'กรุณาใส่จำนวนครั้ง'
                            },
                            {
                              type: 'number',
                              min: 1,
                              message: 'จำนวนต้องมากกว่าหรือเท่ากับ 1'
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value !== null && value !== undefined) {
                                  if (parseFloat(getFieldValue('times')) >= value) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject(new Error('จำนวนที่ใช้มากกว่าสินค้า'));
                                  }
                                } else {
                                  return Promise.resolve(); 
                                }
                              }
                            }),
                            ]} 
                            
                            
                            style={{ marginBottom: "0px" , marginTop:"10px" }}>
                            <InputNumber placeholder='จำนวนครั้ง'/>
                            </Form.Item>

                            <Form.Item name="handfee_times"  
                              label="จำนวนครั้งของค่ามือ" 
                              rules={[
                                { required: true ,message: 'กรุณาใส่จำนวนครั้งของค่ามือ'},
                                {
                                  type: 'number',
                                  min: 0,
                                  max: 100,
                                  message: 'จำนวนต้องอยู่ระหว่าง 0 ถึง 100 เท่านั้น',
                                },
                            ]} 
                              style={{ marginBottom: "0px" , marginTop:"10px" }}>
                              <InputNumber placeholder='จำนวนครั้งของค่ามือ'></InputNumber>
                            </Form.Item>
                                    </div>
                                )}
                              </Radio.Group>
                            </Form.Item>

              

            

            <Form.Item className='flex justify-center' >
                <Button htmlType="submit">
                  เพิ่มรายการ
                </Button>
            </Form.Item>
            
          </Form>

            <Table components={components} rowClassName={() => 'editable-row'} columns={columns}  dataSource={cart} pagination={{ pageSize: 3}} size="small" rowKey="id" className={tableCSS} scroll={{x: 'auto'}}/>
           
                <Form form={form3}>
                  <Form.Item  style={{ marginBottom: "10px" }}
                      label="วิธีการชำระเงิน"
                      name="payment_method"
                      rules={[
                        {
                          required: true,
                          message: 'กรุณาเลือกประเภท',
                        },
                      ]}
                    >
                      <Radio.Group onChange={()=>{setIsPayOptionsSelected(true)}}>
                        <Radio value="เงินสด"> เงินสด </Radio>
                        <Radio value="โอนธนาคาร"> โอนธนาคาร </Radio>
                        <Radio value="บัตรเครดิต/เดบิต"> บัตรเครดิต/เดบิต </Radio>
                        <Radio value="ไม่มี"> ไม่มี</Radio>
                      </Radio.Group>
                    </Form.Item>
                </Form>

                <Form 
                  form={form3} 
                  initialValues={{ date: dayjs().tz(bangkokTimezone) }}
                >
                  <Form.Item  style={{ marginBottom: "10px" }}
                      label="ส่วนลดเพิ่มเติม"
                      name="additional_discount"
                      rules={[
                        {
                          type:Number,
                          required:false,
                        },
                          ({ getFieldValue }) => ({
                            
                          validator: (_, value) => {
                            if (value === undefined || value === null) {
                              setIsAdditionalDiscountValid(false)
                              return Promise.reject(new Error(`ส่วนลดต้องอยู่ในช่วง 0 ถึง  ${priceValue} `));
                            }
                            const numberValue = Number(value);
                            const priceValue =  Number((cart.reduce((acc, item) => acc + (item.price_to_paid), 0)))
                            if (isNaN(numberValue)) {
                              setIsAdditionalDiscountValid(false)
                              return Promise.reject(new Error('ส่วนลดต้องเป็นตัวเลขเท่านั้น'));
                            }
                            if (numberValue < 0 || numberValue > priceValue ) {
                              setIsAdditionalDiscountValid(false)
                              return Promise.reject(new Error(`ส่วนลดต้องอยู่ในช่วง 0 ถึง ${priceValue} `));
                            }
                            setIsAdditionalDiscountValid(true)
                            return Promise.resolve();
                          }
                        }),
                      ]}
                    >
                      <InputNumber placeholder='ส่วนลดเพิ่มเติม' onChange={(value)=>{setAdditionalDiscount(value)}}/>
                    </Form.Item >
                    <Form.Item style={{ marginBottom: "10px" }}
                      label="หมายเหตุ"
                      name="remark"
                      rules={[
                        {
                          max: 200,
                          message: 'หมายเหตุต้องไม่เกิน 200 ตัวอักษร',
                        },
                      ]}>
                      <TextArea style={{ width: "20rem" }} maxLength={200}>
                      </TextArea>    
                    </Form.Item>

                    <Form.Item name="date" label="วันที่" rules={[{ required: true, message: 'กรุณาเลือกวันที่' }]} style={{ marginBottom: "10px" }}>
                      <DatePicker
                        className="custom-datepicker"
                        disabledDate={(current) => current && current > moment().endOf('day')}
                        disabledTime={(current) => {
                          const now = moment();
                          if (current && current.isSame(now, 'day')) {
                            return {
                              disabledHours: () => Array.from({ length: 24 }, (_, i) => i).splice(now.hour() + 1),
                              disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i).splice(now.minute() + 1),
                              disabledSeconds: () => Array.from({ length: 60 }, (_, i) => i).splice(now.second() + 1),
                            };
                          }
                          return {};
                        }}
                        format="YYYY-MM-DD HH:mm"
                        showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm') }}
                        disabled={authState.role !== 'admin'}
                      />
                    </Form.Item>
                </Form>
            <div className='flex '>
            <Statistic title="ราคาสุทธิ" value={totalPrice} precision={2} className='pr-10' /> 
            <Statistic title="ส่วนลด" value={cdiscount} precision={2} className='pr-10'/>
            <Statistic title="ราคารวม" value={finalprice - parseFloat(additionalDiscount || 0)} precision={2} />   
            </div>
        </Modal>

        {/*Billing*/}
        <Modal width={1200} title="ออกบิล" open={isBillModalOpen} onCancel={handelBillModalCancle} footer={[<Button key="ok" onClick={billCreate} disabled={checkSelectedRow()}>ตกลง</Button>,<Button key="close" onClick={handelBillModalCancle} >ปิด</Button>]}>

          <Checkbox checked={isEmployeeUnlock} onChange={onCheckChange}>ปลดล็อคพนักงาน[สำหรับพนักงานต่างสาขา]</Checkbox>
          <Table
            components={components}
            rowClassName={() => 'editable-row'}
            columns={columnsBilling} // Use the editable columns for billing
            dataSource={selectedRow}
            bordered
            className={tableCSS}
            size="small"
            scroll={{x: 'auto'}}
          />
          <Form 
            form={form3}
            initialValues={{ date: dayjs().tz(bangkokTimezone) }}
          >
                  <Form.Item  style={{ marginBottom: "10px" }}
                      label="วิธีการชำระเงิน"
                      name="payment_method"
                      rules={[
                        {
                          required: true,
                          message: 'กรุณาเลือกประเภท',
                        },
                      ]}
                    >
                      <Radio.Group onChange={()=>{setIsPayOptionsSelected(true)}} >
                        <Radio value="เงินสด" > เงินสด </Radio>
                        <Radio value="โอนธนาคาร" > โอนธนาคาร </Radio>
                        <Radio value="บัตรเครดิต/เดบิต"> บัตรเครดิต/เดบิต </Radio>
                        <Radio value="ไม่มี" > ไม่มี</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: "10px" }}
                      label="หมายเหตุ"
                      name="remark"
                      rules={[
                        {
                          max: 200,
                          message: 'หมายเหตุต้องไม่เกิน 100 ตัวอักษร',
                        },
                      ]}>
                      <TextArea style={{ width: "20rem" }} maxLength={200}>
                      </TextArea>    
                    </Form.Item>

                    <Form.Item name="date" label="วันที่" rules={[{ required: true, message: 'กรุณาเลือกวันที่' }]} style={{ marginBottom: "10px" }}>
                      <DatePicker
                        disabledDate={(current) => current && current > moment().endOf('day')}
                        disabledTime={(current) => {
                          const now = moment();
                          if (current && current.isSame(now, 'day')) {
                            return {
                              disabledHours: () => Array.from({ length: 24 }, (_, i) => i).splice(now.hour() + 1),
                              disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i).splice(now.minute() + 1),
                              disabledSeconds: () => Array.from({ length: 60 }, (_, i) => i).splice(now.second() + 1),
                            };
                          }
                          return {};
                        }}
                        format="YYYY-MM-DD HH:mm"
                        showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm') }}
                        disabled={authState.role !== 'admin'}
                      />
                    </Form.Item>
                    
                
                </Form>

            <Statistic title="Total Cost (บาท)" value={totalPrice} precision={2} /> 
        </Modal>

        {/*Delete Order*/}
        <Modal width={500} title="ยืนยันการลบ" open={isConfirmDeleteModalOpen} onCancel={handleDeleteCancel} footer={false}>
        
        <p>Bill ID: {confirmDeleteData?.id}</p>
        <p>ORDER ID: {confirmDeleteData?.order_id}</p>
        <p>ราคาที่จ่าย: {confirmDeleteData?.price_to_paid} บาท</p>
        {isLastBillInOrder && <p className='text-red-500 font-bold'> คุณกำลังลบบิลอันสุดท้าย ซึ่งจะทำให้สินค้านี้ถูกลบไปทั้งหมด</p> }
                  
        <Form
        name="basic"
        form={formRef2}
        initialValues={{
          remember: true,
        }}
        onFinish={handleDeleteConfirm}
        autoComplete="off"
      >

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>


        <div className='flex gap-2 justify-center'>
            <Form.Item
            >
              <Button htmlType="submit" >
                ตกลง
              </Button>
              <Button onClick={handleDeleteCancel} >
                ยกเลิก
              </Button>
            </Form.Item>
          </div>
        </Form>
        </Modal>

        
    </>
    
  )
}
export default CourseDetail